import * as Actions from '../actionsgazeta';

const initialState = [];

const secoes = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SECOES_DATA:
            {
                return action.payload

            }
        case Actions.CLEAR_SECOES_DATA:
            {
                return state;
            }
        default:
            {
                return state
            }
    }
};

export default secoes;
