import React, { Fragment, useState } from 'react';
import FontAwesome from "../uiStyle/FontAwesome";
import Swiper from 'react-id-swiper';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { getMidiaUrl } from '../../store/settings';


const Galeria = (props) => {
    const [swiper, setSwiper] = useState(null);
    const [openLightBox, setOpenLightBox] = useState(false);
    const [ordem, setOrdem] = useState(0);

    const [lista] = useState(props.midias)

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };
    const params = {
        slidesPerView: 3,
        spaceBetween: 25,
        breakpoints: {
            1024: {
                slidesPerView: 3,
                spaceBetween: 25
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 25
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            }
        }
    };
    return (
        <Fragment>
            {openLightBox &&
                <Lightbox
                    reactModalStyle={{ zIndex: 1200 }}
                    mainSrc={getMidiaUrl(lista[ordem].midia.chave)}
                    nextSrc={getMidiaUrl((lista[(ordem + 1) % lista.length].midia.chave))}
                    prevSrc={getMidiaUrl(lista[(ordem + lista.length - 1) % lista.length].midia.chave)}
                    onCloseRequest={() => setOpenLightBox(false)}
                    onMovePrevRequest={() =>
                        setOrdem((ordem + lista.length - 1) % lista.length)
                    }
                    onMoveNextRequest={() =>
                        setOrdem((ordem + lista.length + 1) % lista.length)
                    }
                />
            }
            <div className="feature3 mb30">
                <div className="row">
                    <div className="col-12">
                        <div className="heading padding20 white_bg mb20 border-radious5">
                            <h3 className="widget-title margin0">Galeria</h3>
                        </div>
                    </div>
                </div>
                <div className="feature3_carousel owl-carousel nav_style1">
                    <Swiper getSwiper={setSwiper} {...params}>
                        {lista.map((item, key) => (
                            <div key={key} className="single_post type19 border-radious5 white_bg">
                                <div className="post_img">
                                    <div className="img_wrap">
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setOrdem(key)
                                                setOpenLightBox(true)
                                            }}>
                                            <img src={getMidiaUrl(item.midia.chave)} alt="thumb" />
                                        </div>
                                    </div>
                                </div>
                                <div className="single_post_text padding20">
                                    <p className="post-p" style={{ fontSize: 12 }}>{item.legenda}</p>
                                </div>
                            </div>
                        ))}
                    </Swiper>
                    <div className="navBtns">
                        <div onClick={goPrev} className="navBtn prevtBtn"><FontAwesome name="angle-left" /></div>
                        <div onClick={goNext} className="navBtn nextBtn"><FontAwesome name="angle-right" /></div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Galeria;