import { api } from '../settings'
import axios from 'axios';


export const send = (formulario) => {
    return new Promise(async(resolve, reject) => {
        const apiService = `/open/contato`
        const request = axios.post(`${api}${apiService}`, formulario);
        try {
            await request
            resolve()

        } catch (error) {
            console.log(error)
            reject()
        }
    })
}
