import React, { useState, useLayoutEffect } from 'react';
import { Link } from "react-router-dom";
import FooterCopyright from "../FooterCopyright";
import FooterMoreNews from "../FooterMoreNews";
import TwitterFeed from "../TwitterFeed";
import FooterNewsCategories from "../FooterNewsCategories";
import { useSelector } from "react-redux";
import LiRedes from "../../components/LiRedes";
import { getMidiaUrlPropaganda } from '../../store/settings';

const FooterArea = ({ className }) => {
    const secoes = useSelector(state => state.gazeta.secoes)
    const propagandas = useSelector(state => state.gazeta.propagandas)

    const [dados, setDados] = useState([])
    const colunistas = useSelector(state => state.gazeta.colunistas)

    useLayoutEffect(() => {
        // se injetar direto a MaterialTable buga o redux
        let ajuste = colunistas.map((item, key) => {
            return Object.assign(
                {},
                item,
                { link: `/colunistas/${item.chave}` }
            )
        })
        Promise.all(ajuste).then(values => setDados(values))

    }, [colunistas])

    return (
        <div className={`footer footer_area1 ${className ? className : ''}`}>
            <div className="container">
                <div className="cta">
                    <div className="row">
                        <div className="col-md-12 align-self-center">
                            <div className="footer_logo">
                                {
                                    propagandas.find(propaganda => propaganda.funcao === "Footer") ?
                                        <a target="_blank" rel="noopener noreferrer" href={propagandas.find(propaganda => propaganda.funcao === "Footer").link}>
                                            <img src={getMidiaUrlPropaganda(propagandas.find(propaganda => propaganda.funcao === "Footer").chave)} alt="banner" />
                                        </a>
                                        : null
                                }
                                {
                                    //<div className="clever-core-ads"></div>
                                }


                            </div>
                            <div className="social2">
                                <ul className="inline">
                                    <LiRedes />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border_white" />
                <div className="space-40" />
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-sm-6 col-lg">
                                <div className="single_footer_nav border_white_right">
                                    <FooterNewsCategories />
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg">
                                <div className="single_footer_nav">
                                    <h3 className="widget-title2">Colunistas</h3>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <ul>
                                                {dados.map((item, key) => {
                                                    let total = dados.length
                                                    if (key < total / 2)
                                                        return <li key={key}><Link to={`${item.link}`}>{item.nome}</Link></li>
                                                    else
                                                        return null
                                                })}
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul>
                                                {dados.map((item, key) => {
                                                    let total = dados.length
                                                    if (key > total / 2)
                                                        return <li key={key}><Link to={`${item.link}`}>{item.nome}</Link></li>
                                                    else
                                                        return null
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="space-40" />
                        <div className="border_white" />
                        <div className="space-40" />
                        <div className="row">
                            <div className="col-sm-6 col-lg-5">
                                <div className="single_footer_nav border_white_right">
                                    <h3 className="widget-title2">Seções</h3>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <ul>
                                                {secoes.filter(item => item.tipo === 1).map((item, key) =>
                                                    <li key={key}><Link to={`/secao/${item.chave}`}>{item.menu}</Link></li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-7">
                                <TwitterFeed />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <FooterMoreNews />
                    </div>
                </div>
                <div style={{ height: 50, textAlign: "center", marginTop: 10 }}>
                    <span style={{ color: "white", marginTop: 10 }}>
                        Plataforma criada e mantida por <br />
                        Rádio e Televisão Gazeta de Carazinho Ltda.</span>
                </div>


            </div>
            <FooterCopyright />
        </div>
    );
};

export default FooterArea;