import React, { useState, useLayoutEffect, useEffect } from 'react';
import FontAwesome from "../uiStyle/FontAwesome";
import { Link } from "react-router-dom";
import Swiper from 'react-id-swiper';
import { useSelector } from "react-redux";
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { getMidiaUrl } from '../../store/settings';

const MixCarousel = ({ className, dark }) => {
    const [swiper, setSwiper] = useState(null);
    const [dados, setDados] = useState([])
    const comunicados = useSelector(state => state.gazeta.comunicados)
    useLayoutEffect(() => {
        // se injetar direto a MaterialTable buga o redux
        let ajuste = comunicados.map((item, key) => {
            var now = moment(new Date()); //todays date
            var end = moment(item.data, 'YYYY-MM-DDTHH:mm.Z'); // another date
            var duration = moment.duration(now.diff(end));
            var diferencaMin = duration.asMinutes();
            let tempo = ""
            if (diferencaMin < 60) tempo = `Há ${parseInt(diferencaMin)} ${parseInt(diferencaMin) > 1 ? "minutos" : "minuto"} `
            else if (diferencaMin < 1440) tempo = `Há ${parseInt(diferencaMin / 60)} ${parseInt(diferencaMin / 60) > 1 ? "horas" : "hora"}`
            else if (diferencaMin > 1440) tempo = `Há ${parseInt(diferencaMin / 1440)} ${parseInt(diferencaMin / 1440) > 1 ? "dias" : "dia"}`
            return Object.assign(
                {},
                item,
                { tempo },
                { data: moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("DD/MM/YYYY HH:mm") },
                { link: `/comunicado/${item.chave}` }
            )
        })
        Promise.all(ajuste).then(values => setDados(values))

    }, [comunicados])

    useEffect(() => {
        if (swiper)
            swiper.update()
    }, [dados, swiper])


    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };
    const params = {
        slidesPerView: comunicados.length > 1 ? isMobile ? 1 : 2 : 1,
        spaceBetween: 30,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        breakpoints: {
            1024: {
                slidesPerView: comunicados.length > 1 ? isMobile ? 1 : 2 : 1,
                spaceBetween: 30
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            300: {
                slidesPerView: 1,
                spaceBetween: 0
            },
        }
    };
    return (
        <div className={`mix_area ${className ? className : ''}`}>
            <div className="container">
                <div className="row">
                    {isMobile ?
                        <>
                            <div className={"col-12"}>
                                {comunicados.length > 0 ?
                                    <div className={`mix_carousel ${dark ? 'primay_bg' : ''}`}>
                                        {/*CAROUSEL START*/}
                                        <div className="single_mix_carousel nav_style3">
                                            <Swiper getSwiper={setSwiper} {...params}>
                                                {dados.map((item, i) => (
                                                    <div key={i} className="single_post post_type6 post_type9">
                                                        {item.chaveMidia ?
                                                            <>
                                                                <div className="post_img gradient1">
                                                                    <div className="img_wrap" style={{ height: 200 }}>
                                                                        <Link className="play_btn" to={`${item.link}`}>
                                                                            <img src={getMidiaUrl(item.chaveMidia)} alt="news"
                                                                            />
                                                                        </Link>
                                                                    </div>
                                                                    {
                                                                        item.icon ?
                                                                            <span
                                                                                className={`tranding ${i % 2 ? 'left' : ''}`}>
                                                                                <FontAwesome
                                                                                    name={item.icon} /></span>
                                                                            : null}
                                                                </div>
                                                                <div className="single_post_text">
                                                                    <div className="meta">
                                                                        <Link to={`${item.link}`}>{item.tempo}</Link>
                                                                    </div>
                                                                    <h4><Link to={`${item.link}`}>{item.titulo}</Link></h4>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div style={{ padding: 20, paddingLeft: 40, paddingTop: 100 }}>
                                                                    {
                                                                        item.icon ?
                                                                            <span
                                                                                className={`tranding ${i % 2 ? 'left' : ''}`}>
                                                                                <FontAwesome
                                                                                    name={item.icon} /></span>
                                                                            : null}
                                                                    <div className="meta3">
                                                                        <Link
                                                                            to={`${item.link}`}
                                                                            style={{ fontSize: 12 }}
                                                                        >{item.tempo}</Link>
                                                                    </div>
                                                                    <h4><Link
                                                                        to={`${item.link}`}
                                                                        style={{ color: "black" }}
                                                                    >{item.titulo}</Link></h4>
                                                                    <p style={{ fontSize: 12 }}>{item.subTitulo}</p>
                                                                </div>
                                                            </>
                                                        }

                                                    </div>
                                                ))}
                                            </Swiper>
                                            <div className="owl-nav">
                                                <div onClick={goPrev} className="owl-prev"><FontAwesome name="angle-left" /></div>
                                                <div onClick={goNext} className="owl-next"><FontAwesome name="angle-right" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                                {/*CAROUSEL END*/}
                            </div>
                        </>
                        :
                        <>
                            <div className="col-2"></div>
                            {comunicados.length === 1 ?
                                <div className="col-2"></div>
                                : null
                            }
                            <div className={
                                comunicados.length === 1 ?
                                    "col-4"
                                    :
                                    "col-8"
                            }>
                                {comunicados.length > 0 ?
                                    <div className={`mix_carousel ${dark ? 'primay_bg' : ''}`}>
                                        {/*CAROUSEL START*/}
                                        <div className="single_mix_carousel nav_style3">
                                            <Swiper getSwiper={setSwiper} {...params}>
                                                {dados.map((item, i) => (
                                                    <div key={i} className="single_post post_type6 post_type9">
                                                        {item.chaveMidia ?
                                                            <>
                                                                <div className="post_img gradient1">
                                                                    <div className="img_wrap">
                                                                        <Link className="play_btn" to={`${item.link}`}>
                                                                            <img src={getMidiaUrl(item.chaveMidia)} alt="news"
                                                                                height={isMobile ? 150 : 350}
                                                                            />
                                                                        </Link>
                                                                    </div>
                                                                    {
                                                                        item.icon ?
                                                                            <span
                                                                                className={`tranding ${i % 2 ? 'left' : ''}`}>
                                                                                <FontAwesome
                                                                                    name={item.icon} /></span>
                                                                            : null}
                                                                </div>
                                                                <div className="single_post_text">
                                                                    <div className="meta">
                                                                        <Link to={`${item.link}`}>{item.tempo}</Link>
                                                                    </div>
                                                                    <h4><Link to={`${item.link}`}>{item.titulo}</Link></h4>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div style={{ height: 350, padding: 20, paddingLeft: 40, paddingTop: 100 }}>
                                                                    {
                                                                        item.icon ?
                                                                            <span
                                                                                className={`tranding ${i % 2 ? 'left' : ''}`}>
                                                                                <FontAwesome
                                                                                    name={item.icon} /></span>
                                                                            : null}
                                                                    <div className="meta3">
                                                                        <Link
                                                                            to={`${item.link}`}
                                                                            style={{ fontSize: 12 }}
                                                                        >{item.tempo}</Link>
                                                                    </div>
                                                                    <h4><Link
                                                                        to={`${item.link}`}
                                                                        style={{ color: "black" }}
                                                                    >{item.titulo}</Link></h4>
                                                                    <p style={{ fontSize: 12 }}>{item.subTitulo}</p>
                                                                </div>
                                                            </>
                                                        }

                                                    </div>
                                                ))}
                                            </Swiper>
                                            <div className="owl-nav">
                                                <div onClick={goPrev} className="owl-prev"><FontAwesome name="angle-left" /></div>
                                                <div onClick={goNext} className="owl-next"><FontAwesome name="angle-right" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                                {/*CAROUSEL END*/}
                            </div>
                        </>
                    }
                </div>
            </div>
        </div >
    );
};

export default MixCarousel;