import React, { useLayoutEffect, useState, Fragment } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Swiper from 'react-id-swiper';
import FontAwesome from "../uiStyle/FontAwesome";
import { isMobile } from 'react-device-detect';
import { getMidiaUrlPrograma } from '../../store/settings';

const Programas = () => {
    const programas = useSelector(state => state.gazeta.programas)
    const [dados, setDados] = useState([])
    const [swiper, setSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };
    const params = {
        slidesPerView: 3,
        spaceBetween: 25,
        breakpoints: {
            1024: {
                slidesPerView: 3,
                spaceBetween: 25
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 25
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            }
        }
    };

    useLayoutEffect(() => {
        // se injetar direto a MaterialTable buga o redux
        let dados = programas.map((item, key) => {
            return Object.assign(
                {},
                item,
                { link: `/programa/${item.chave}` }
            )
        })
        Promise.all(dados).then(values => {
            setDados(values)
        })

    }, [programas])


    return (
        <>
            {dados.length > 0 ?
                <>
                    <div className="mb40" style={{ backgroundColor: "#191919", color: "white" }}>
                        <div className="container">

                            <div className="row">
                                <div className={!isMobile ? "col-3" : "col-12"} style={{ padding: 15 }} >
                                    <h3 style={{ color: "white" }}>
                                        Ouça nossos programas nas principais plataformas de áudio:
                                    </h3>
                                </div>
                                <div className={!isMobile ? "col-9" : "col-12"}>

                                    <Fragment>
                                        <div className="feature3 mb30" style={{ marginTop: 10 }}>
                                            <div className="feature3_carousel owl-carousel nav_style1" style={{ padding: 10 }}>
                                                <>
                                                    <Swiper getSwiper={setSwiper} {...params}>
                                                        {dados.map((item, i) => (
                                                            <div key={i} className="single_post type19 border-radious5 white_bg">
                                                                <div className="post_img">
                                                                    <div className="img_wrap">
                                                                        <Link to={`/programa/${item.chave}`}>
                                                                            <img src={getMidiaUrlPrograma(item.chave)} alt="thumb" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Swiper>
                                                    <div className="navBtns" style={{ top: "90%", zIndex: 100000 }}>
                                                        <div onClick={goPrev} className="navBtn prevtBtn"><FontAwesome name="angle-left" /></div>
                                                        <div onClick={goNext} className="navBtn nextBtn"><FontAwesome name="angle-right" /></div>
                                                    </div>
                                                </>
                                            </div>
                                        </div>
                                    </Fragment>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : null
            }
        </>
    );

};

export default Programas;