import { api } from '../settings'
import axios from 'axios';
export const SET_PESQUISA_DATA = 'SET_PESQUISA_DATA';
export const CLEAR_PESQUISA_DATA = 'CLEAR_PESQUISA_DATA';

export const get = (limit = 10, offSet = 0, textSearch, typeTextSearch) => async (dispatch, getState) => {
    let apiService = `/open/noticias`
    apiService += `?limit=${limit}`
    apiService += `&textSearch=${textSearch}`
    apiService += `&offset=${offSet}`
    apiService += `&typeTextSearch=${typeTextSearch}`
    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type: SET_PESQUISA_DATA
            ,
            payload: response.data
        })

       

    } catch (error) {
        console.log(error)
    }
}
