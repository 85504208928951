import React, { Fragment, useState, useLayoutEffect, useEffect, useCallback } from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import FontAwesome from "../../components/uiStyle/FontAwesome";
import { Link } from "react-router-dom";
import { Fade, Nav, NavItem, TabContent, TabPane, Button } from "reactstrap";
import classnames from 'classnames';
import user from '../../doc/img/header/user.png';
import * as ColunasColunistaAction from '../../store/actionsgazeta/colunasColunista.actions'
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import SidePage from "../../components/SidePage";
import { MetaTags } from 'react-meta-tags';
import { title_global, description_global } from '../../store/variables';
import { getMidiaUrl } from '../../store/settings';

const ColunistaPage = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const dispatch = useDispatch();
    const colunistas = useSelector(state => state.gazeta.colunistas)
    const colunasColunista = useSelector(state => state.gazeta.colunasColunista)
    const [dados, setDados] = useState([])
    const [offset, setOffset] = useState(0)



    useEffect(() => {
        // se injetar direto a MaterialTable buga o redux
        let ajuste = colunasColunista.map((item, key) => {
            var now = moment(new Date()); //todays date
            var end = moment(item.data, 'YYYY-MM-DDTHH:mm.Z'); // another date
            var duration = moment.duration(now.diff(end));
            var diferencaMin = duration.asMinutes();
            let tempo = ""
            if (diferencaMin < 60) tempo = `Há ${parseInt(diferencaMin)} ${parseInt(diferencaMin) > 1 ? "minutos" : "minuto"} `
            else if (diferencaMin < 1440) tempo = `Há ${parseInt(diferencaMin / 60)} ${parseInt(diferencaMin / 60) > 1 ? "horas" : "hora"}`
            else if (diferencaMin > 1440) tempo = `Há ${parseInt(diferencaMin / 1440)} ${parseInt(diferencaMin / 1440) > 1 ? "dias" : "dia"}`
            return Object.assign(
                {},
                item,
                { tempo },
                { data: moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("DD/MM/YYYY HH:mm") },
                { link: `/coluna/${item.chave}` }
            )
        })
        Promise.all(ajuste).then(values => {
            setDados(values)
        })

    }, [colunasColunista])


    const changePage = async (param) => {
        window.scroll(0, 0)
        let muda = offset + param
        if (muda < 0)
            setOffset(0)
        else
            setOffset(muda)
        buscarRegistros(muda)

    }


    const buscarRegistros = useCallback((param) => {
        let item = colunistas.find(item => item.chave === props.match.params.chave)
        if (item) {
            setColunista(item)
            dispatch(ColunasColunistaAction.get(10, item.chave, param))
        }
    }, [dispatch, colunistas, props.match.params])

    const [colunista, setColunista] = useState({})

    useLayoutEffect(() => {
        window.scroll(0, 0)
        buscarRegistros(0)

    }, [colunistas, buscarRegistros])

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    return (
        <Fragment>
            <MetaTags>
                <title>{title_global}</title>
                <meta name="description" content={description_global} />
                <meta property="og:title" content={title_global} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={"https://gazeta670.com.br"} />
            </MetaTags>
            <BreadCrumb className="shadow5" title="">
                <Fragment>
                    <div className="space-50" />
                    <div className="row">
                        <div className="col-2">
                            <div >
                                {
                                    colunista.colunistaMidia && colunista.colunistaMidia.find(itemColunista => itemColunista.funcao === "Perfil") ?
                                        <img
                                            src={getMidiaUrl(colunista.colunistaMidia.find(itemColunista => itemColunista.funcao === "Perfil").midia.chave)}
                                            alt="Foto Colunista"
                                            style={{ maxHeight: 200, borderRadius: 10 }}
                                        />
                                        : <img src={user} alt="author2" style={{ maxHeight: 200 }} />
                                }

                            </div>
                        </div>
                        <div className="col-10">


                            <div>
                                <h2>
                                    {colunista.nome}
                                </h2>
                                <p><a style={{ fontSize: 12 }} href={`mailto:${colunista.email}`}>{colunista.email}</a> <span style={{ fontSize: 12 }}>{colunista.telefone}</span> </p>
                                <p>{colunista.minibio}</p>
                            </div>
                        </div>
                    </div>
                    <div className="space-50" />
                </Fragment>
            </BreadCrumb >
            <div className="archives padding-top-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            <div className="row">
                                <div className="col-8 align-self-center">
                                    <div className="about_post_list">
                                        <Nav tabs>
                                            <NavItem>
                                                <div
                                                    className={classnames({ active: activeTab === '1' })}
                                                    onClick={() => {
                                                        toggle('1');
                                                    }}
                                                >
                                                    Colunas
                                                </div>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </div>
                                <div className="col-4 text-right align-self-center">
                                    <div className="calender mb20">
                                        <FontAwesome
                                            name="list-ol" /> 10 últimas
                                    </div>
                                </div>
                            </div>
                            <div className="about_posts_tab">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Fade in={activeTab === '1'}>
                                            <div className="row justify-content-center">
                                                {dados.map((item, i) => (
                                                    <div key={i} className="col-lg-6">
                                                        {item.chaveMidia ?
                                                        <div className="single_post post_type3 mb30">
                                                            <div className="post_img">
                                                                <div className="img_wrap">
                                                                    <Link to={`${item.link}`}>
                                                                        <img src={getMidiaUrl(item.chaveMidia)} alt="thumb" />
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className="single_post_text">
                                                                <div className="meta3">
                                                                    {item.categorias ? item.categorias.map((item, key, row) =>
                                                                        <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                                            {`${item.nome}`}
                                                                            {key + 1 !== row.length ? " /" : null}
                                                                        </Link>
                                                                    ) : null}
                                                                    <Link to={`${item.link}`}>{item.tempo}</Link>
                                                                </div>
                                                                <h4><Link to={`${item.link}`}>{item.titulo}</Link></h4>
                                                                <div className="space-10" />
                                                                <p className="post-p">{item.subTitulo}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="single_post_text" style={{ padding: 5 }}>
                                                                <div className="meta3">
                                                                    {item.categorias ? item.categorias.map((item, key, row) =>
                                                                        <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                                            {`${item.nome}`}
                                                                            {key + 1 !== row.length ? " /" : null}
                                                                        </Link>
                                                                    ) : null}
                                                                    <Link to={`${item.link}`}>{item.tempo}</Link>
                                                                </div>

                                                                <h4><Link to={`${item.link}`} style={{ fontSize: 30, lineHeight: 1.2 }}>{item.titulo}</Link></h4>
                                                                <div className="space-10" />
                                                                <p className="post-p">{item.subTitulo}</p>
                                                            </div>

                                                        </>
                                                        }

                                                    </div>
                                                ))}
                                            </div>
                                        </Fade>
                                    </TabPane>

                                </TabContent>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="cpagination">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <Button className="page-link" aria-label="Previous"
                                                        style={{ width: 200, fontSize: 16 }}
                                                        onClick={() => changePage(-10)}
                                                    >
                                                        <span aria-hidden="true"><FontAwesome
                                                            name="caret-left" /> Voltar</span>
                                                    </Button>
                                                </li>
                                                <li className="page-item">
                                                    <Button className="page-link" aria-label="Next"
                                                        style={{ width: 200, fontSize: 16 }}
                                                        onClick={() => changePage(10)}
                                                    >
                                                        <span aria-hidden="true">Próxima Página
                                                            <FontAwesome
                                                                name="caret-right"
                                                                style={{ marginLeft: 10 }}
                                                            />
                                                        </span>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <SidePage
                                patrocinios={true}
                                redes={false}
                                face={false}
                                maisLidas={false}
                                colunistas={true}
                                ultimas={false}
                                ultimasColunas={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-70" />
        </Fragment >
    )
};

export default ColunistaPage;