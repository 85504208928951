import * as Actions from '../actionsgazeta';

const initialState = {};

const previsao = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_PREVISAO_DATA:
            {
                return {
                    ...action.payload
                };
            }
        case Actions.CLEAR_PREVISAO_DATA:
            {
                return {

                };
            }
        default:
            {
                return state
            }
    }
};

export default previsao;
