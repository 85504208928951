import { api } from '../settings'
import axios from 'axios';

export const SET_CATEGORIAS_DATA = 'SET_CATEGORIAS_DATA';
export const CLEAR_CATEGORIAS_DATA = 'CLEAR_CATEGORIAS_DATA';

export const get = () => async (dispatch, getState) => {
    const apiService = `/open/categoria`
    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type: SET_CATEGORIAS_DATA,
            payload: response.data
        })

    } catch (error) {
        console.log(error)
    }
}
