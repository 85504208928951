import React, { useState, useLayoutEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment';
import { getMidiaUrl } from '../../store/settings';

const BusinessNews = ({ businessNews = [], headerHide = true }) => {

    const colunas = useSelector(state => state.gazeta.colunasCapa)
    const [dados, setDados] = useState([])

    useLayoutEffect(() => {
        // se injetar direto a MaterialTable buga o redux
        let ajuste = colunas.map((item, key) => {
            var now = moment(new Date()); //todays date
            var end = moment(item.data, 'YYYY-MM-DDTHH:mm.Z'); // another date
            var duration = moment.duration(now.diff(end));
            var diferencaMin = duration.asMinutes();
            let tempo = ""
            if (diferencaMin < 60) tempo = `Há ${parseInt(diferencaMin)} ${parseInt(diferencaMin) > 1 ? "minutos" : "minuto"} `
            else if (diferencaMin < 1440) tempo = `Há ${parseInt(diferencaMin / 60)} ${parseInt(diferencaMin / 60) > 1 ? "horas" : "hora"}`
            else if (diferencaMin > 1440) tempo = `Há ${parseInt(diferencaMin / 1440)} ${parseInt(diferencaMin / 1440) > 1 ? "dias" : "dia"}`
            return Object.assign(
                {},
                item,
                { tempo },
                { data: moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("DD/MM/YYYY HH:mm") },
                { link: `/coluna/${item.chave}` }
            )
        })
        Promise.all(ajuste).then(values => {
            setDados(values)
        })

    }, [colunas])

    return (
        <div className="row">
            <div className="col-12">
                <div className="businerss_news">

                    <div className="row">
                        <div className="col-6 align-self-center">
                            <h2 className="widget-title">Últimos Blogs</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {dados.map((item, i) => (
                                <div key={i} >
                                    {
                                        item.chaveMidia ?
                                            <div className="single_post post_type3 post_type12 mb30">
                                                <div className="post_img">
                                                    <div className="img_wrap">
                                                        <Link to={`${item.link}`}>
                                                            <img src={getMidiaUrl(item.chaveMidia)} alt="thumb"
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="single_post_text">
                                                    <div className="meta3">
                                                        <Link to={`/colunista/${item.chaveColunista}`}>{item.colunista}</Link>
                                                        <Link to={`${item.link}`}>{item.data}</Link>
                                                    </div>
                                                    <h4><Link to={`${item.link}`}>{item.titulo}</Link></h4>
                                                    <div className="space-10" />
                                                    <p className="post-p">{item.subTitulo}</p>
                                                    <div className="space-20" />
                                                    <Link to={`${item.link}`} className="readmore">Saiba mais</Link>
                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div style={{ textAlign: "center", width: "100%", marginBottom: 30 }}>
                                                    <div style={{ fontSize: 12 }}>
                                                        <Link
                                                            to={`${item.link}`}
                                                            style={{ marginRight: 10, color: "#1091FF", fontSize: 14, fontWeight: 500 }}>
                                                            {item.colunista}
                                                        </Link>
                                                        <Link
                                                            to={`${item.link}`}
                                                            style={{ color: "#17222B", fontSize: 14, fontWeight: 500, opacity: "0.75" }}
                                                        >

                                                            {item.data}</Link>
                                                    </div>
                                                    <h4>
                                                        <Link
                                                            to={`${item.link}`}
                                                            style={{ fontSize: 30, lineHeight: 1.2, color: "black" }}
                                                        >{item.titulo}</Link></h4>
                                                    <div className="space-10" />
                                                    <p className="post-p">{item.subTitulo}</p>
                                                    <div className="space-20" />
                                                    <Link to={`${item.link}`} className="readmore">Saiba mais</Link>
                                                </div>
                                            </>
                                    }
                                </div>


                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default BusinessNews;