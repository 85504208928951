import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Swiper from 'react-id-swiper';
import FontAwesome from "../uiStyle/FontAwesome";
import LiRedes from "../../components/LiRedes";
import { useSelector } from "react-redux";
import moment from 'moment'
import 'moment/locale/pt-br'  // without this line it didn't work
moment.locale('pt-br');

const TopBar = ({ className, dark }) => {
    const [swiper, setSwiper] = useState(null);
    const comunicados = useSelector(state => state.gazeta.comunicados)


    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };
    const params = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    };

    useEffect(() => {
        if (swiper)
            swiper.update()
    }, [comunicados, swiper])
    /*
    Últimas Notícias
    */
    return (
        <div className={`topbar ${className ? className : ''}`} id="top">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 align-self-center">
                        {comunicados.length > 0 ?
                            <div className={`trancarousel_area ${dark ? 'white' : ''}`}>
                                <p className="trand">Fique por dentro</p>
                                <div className="nav_style1">
                                    <Swiper getSwiper={setSwiper} className="trancarousel" {...params}>
                                        {
                                            comunicados.map((item, key) =>
                                                <div className="trancarousel_item" key={key}>
                                                    <p><Link to={`/comunicado/${item.chave}`}>
                                                        {item.titulo}
                                                    </Link>
                                                    </p>
                                                </div>
                                            )
                                        }
                                    </Swiper>
                                    <div className="navBtns">
                                        <button className="navBtn prevBtn" onClick={goPrev}><FontAwesome name="angle-left" />
                                        </button>
                                        <button className="navBtn nextBtn" onClick={goNext}><FontAwesome
                                            name="angle-right" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                    <div className="col-md-4 align-self-center">
                        <div className="top_date_social text-right">
                            <div className={`paper_date ${dark ? 'white' : ''}`}>
                                <p>{moment().format("LL")}</p>
                            </div>
                            <div className={`social1 ${dark ? 'white' : ''}`}>
                                <ul className="inline">
                                    <LiRedes />
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBar;