import React, { useState, useLayoutEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment';


const FooterMoreNews = () => {
    const [dados, setDados] = useState([])
    const noticias = useSelector(state => state.gazeta.noticiasMaisLidas)

    useLayoutEffect(() => {
        // se injetar direto a MaterialTable buga o redux
        let ajuste = noticias.map((item, key) => {
            var now = moment(new Date()); //todays date
            var end = moment(item.data, 'YYYY-MM-DDTHH:mm.Z'); // another date
            var duration = moment.duration(now.diff(end));
            var diferencaMin = duration.asMinutes();
            let tempo = ""
            if (diferencaMin < 60) tempo = `Há ${parseInt(diferencaMin)} ${parseInt(diferencaMin) > 1 ? "minutos" : "minuto"} `
            else if (diferencaMin < 1440) tempo = `Há ${parseInt(diferencaMin / 60)} ${parseInt(diferencaMin / 60) > 1 ? "horas" : "hora"}`
            else if (diferencaMin > 1440) tempo = `Há ${parseInt(diferencaMin / 1440)} ${parseInt(diferencaMin / 1440) > 1 ? "dias" : "dia"}`
            return Object.assign(
                {},
                item,
                { tempo },
                { data: moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("DD/MM/YYYY HH:mm") },
                { link: `/noticia/${moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("YYYY")}/${moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("MM")}/${moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("DD")}/${item.chave}` }
            )
        })
        Promise.all(ajuste).then(values => setDados(values))

    }, [noticias])


    return (
        <div className="extra_newss border_white_left pl-4">
            <h3 className="widget-title2">Mais Lidas</h3>
            {dados.map((item, i) => (
                <div key={i} className="single_extra_news border_white_bottom">
                    <p>
                        {item.categorias ? item.categorias.map((item, key, row) =>
                            <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                {`${item.nome}`}
                                {key + 1 !== row.length ? " /" : null}
                            </Link>
                        ) : null}

                        <span> / {item.data}</span></p>
                    <Link to={`${item.link}`}>{item.titulo}</Link>
                    <span className="news_counter">{i + 1}</span>
                </div>
            ))}
        </div>
    );
};

export default FooterMoreNews;