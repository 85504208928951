import * as Actions from '../actionsgazeta';

const initialState = "";

const radio = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_RADIO_DATA:
            {
                return action.payload

            }
        case Actions.CLEAR_RADIO_DATA:
            {
                return "";
            }
        default:
            {
                return state
            }
    }
};

export default radio;
