import { isMobile } from 'react-device-detect';
let mobile = ""
mobile = isMobile ? "?isMobile=true" : ""

export const port = 21331
export const portApp = 3001
export const production = 1
export const localHost = "http://localhost"
export const api = production ? `https://gazeta670.com.br/gazetabackend` : `${localHost}:${port}`
export const app = production ? `https://app.gazeta670.com.br` : `${localHost}:${portApp}`
export const getMidiaUrl = (chave) => api + "/open/midiafile/" + chave + ".jpg"
export const getMidiaUrlPropaganda = (chave) => api + "/open/propagandamidiafile/" + chave + ".jpg" + mobile
export const getMidiaUrlPrograma = (chave) => api + "/open/programamidiafile/" + chave + ".jpg" + mobile

//TODO Configurações