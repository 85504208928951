import { api } from '../settings'
import axios from 'axios';
export const SET_COMUNICADOS_CAPA_DATA = 'SET_COMUNICADOS_CAPA_DATA';
export const CLEAR_COMUNICADOS_CAPA_DATA = 'CLEAR_COMUNICADOS_CAPA_DATA';

export const get = (limit = 10) => async (dispatch, getState) => {
    let apiService = `/open/comunicados`
    apiService += `?limit=${limit}`
    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type: SET_COMUNICADOS_CAPA_DATA,
            payload: response.data
        })

    } catch (error) {
        console.log(error)
        
    }
}
