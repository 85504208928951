import React, { Fragment, useState, useLayoutEffect, useCallback } from 'react';
import BreadCrumb from "../../components/BreadCrumb";
import FontAwesome from "../../components/uiStyle/FontAwesome";
import { Fade, Nav, NavItem, TabContent, TabPane, Button } from "reactstrap";
import classnames from 'classnames';
import * as ProgramaItensAction from '../../store/actionsgazeta/programaItens.actions'
import { useDispatch, useSelector } from "react-redux";
//import moment from 'moment';
import SidePage from "../../components/SidePage";
import { MetaTags } from 'react-meta-tags';
import { title_global, description_global } from '../../store/variables';

const ProgramaPage = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const dispatch = useDispatch();
    const programaItens = useSelector(state => state.gazeta.programaItens)
    const [offset, setOffset] = useState(0)


    const changePage = async (param) => {
        window.scroll(0, 0)
        let muda = offset + param
        if (muda < 0)
            setOffset(0)
        else
            setOffset(muda)
        buscarRegistros(muda)

    }


    const buscarRegistros = useCallback((param) => {
        dispatch(ProgramaItensAction.get(props.match.params.chave, 10, param))
    }, [dispatch, props.match.params])


    useLayoutEffect(() => {
        window.scroll(0, 0)
        buscarRegistros(0)

    }, [buscarRegistros])

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    return (
        <Fragment>
            <MetaTags>
                <title>{title_global}</title>
                <meta name="description" content={description_global} />
                <meta property="og:title" content={title_global} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={"https://gazeta670.com.br"} />
            </MetaTags>
            <BreadCrumb className="shadow5" title="">
                <Fragment>
                    <div className="space-50" />
                    <div className="row">
                        <div className="col-12">
                            <div>
                                <h2>
                                    {programaItens.titulo}
                                </h2>
                                <p>{programaItens.texto}</p>
                            </div>
                        </div>
                    </div>
                    <div className="space-50" />
                </Fragment>
            </BreadCrumb >
            <div className="archives padding-top-30">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            <div className="row">
                                <div className="col-8 align-self-center">
                                    <div className="about_post_list">
                                        <Nav tabs>
                                            <NavItem>
                                                <div
                                                    className={classnames({ active: activeTab === '1' })}
                                                    onClick={() => {
                                                        toggle('1');
                                                    }}
                                                >
                                                    Episódios
                                                </div>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                </div>
                                <div className="col-4 text-right align-self-center">
                                    <div className="calender mb20">
                                        <FontAwesome
                                            name="list-ol" /> 10 últimas
                                    </div>
                                </div>
                            </div>
                            <div className="about_posts_tab">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Fade in={activeTab === '1'}>
                                            <div className="row justify-content-center">
                                                {programaItens.titulo ? programaItens.programaItem.map((item, i) => (
                                                    <div key={i} className="col-lg-12">
                                                        <>
                                                            <div className="single_post_text" style={{ padding: 5 }}>
                                                                <h4>{item.titulo}</h4>
                                                                <div className="space-10" />
                                                                {
                                                                    /*
                                                                    <p className="post-p">{moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("DD/MM/YYYY HH:mm")}</p>
                                                                    */
                                                                }

                                                                <div
                                                                    style={
                                                                        {
                                                                            //textAlign: "left",
                                                                            //fontStretch: "100%",
                                                                            //display: "block",
                                                                            fontSize: 18,
                                                                            color: "#535353",
                                                                            //verticalAlign: "initial",
                                                                            letterSpacing: 0,
                                                                            //lineHeight: "27px",
                                                                            fontWeight: 400,
                                                                            textSizeAdjust: "100%",
                                                                            fontFamily: "opensans,helvetica,arial,sans-serif"
                                                                            //textJustify: "inter-word"
                                                                        }
                                                                    } dangerouslySetInnerHTML={{ __html: item.texto }}></div>
                                                            </div>
                                                        </>
                                                    </div>
                                                )) : null}
                                            </div>
                                        </Fade>
                                    </TabPane>

                                </TabContent>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="cpagination">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <Button className="page-link" aria-label="Previous"
                                                        style={{ width: 200, fontSize: 16 }}
                                                        onClick={() => changePage(-10)}
                                                    >
                                                        <span aria-hidden="true"><FontAwesome
                                                            name="caret-left" /> Voltar</span>
                                                    </Button>
                                                </li>
                                                <li className="page-item">
                                                    <Button className="page-link" aria-label="Next"
                                                        style={{ width: 200, fontSize: 16 }}
                                                        onClick={() => changePage(10)}
                                                    >
                                                        <span aria-hidden="true">Próxima Página
                                                            <FontAwesome
                                                                name="caret-right"
                                                                style={{ marginLeft: 10 }}
                                                            />
                                                        </span>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <SidePage
                                patrocinios={true}
                                redes={false}
                                face={false}
                                maisLidas={false}
                                colunistas={true}
                                ultimas={false}
                                ultimasColunas={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-70" />
        </Fragment >
    )
};

export default ProgramaPage;