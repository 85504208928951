import React, { Fragment, useLayoutEffect, useState } from 'react';
import Galeria from "../../components/Galeria";
import * as ComunicadoAction from '../../store/actionsgazeta/comunicado.actions'
import { useDispatch, useSelector } from "react-redux";
import { MetaTags } from 'react-meta-tags';
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { FacebookProvider, Comments } from 'react-facebook';
import SidePage from "../../components/SidePage";
import { getMidiaUrl, production } from '../../store/settings'
import LiRedes from "../../components/LiRedes";

const ComunicadoPage = (props) => {
    const comunicado = useSelector(state => state.gazeta.comunicado)
    const dispatch = useDispatch();
    const [url, setUrl] = useState("")

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        let chave = props.match.params.chave
        if (chave) {
            if (production)
                setUrl(`https://gazeta670.com.br/comunicado/${chave}`)
            else
                setUrl(`https://gazeta670.com.br/`)
            dispatch(ComunicadoAction.get(chave))
        }

    }, [props.match.params, dispatch]);


    return (
        <Fragment>
            <MetaTags>
                <title>{comunicado.titulo}</title>
            </MetaTags>

            <div className="archives post post1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            <div className="space-30" />
                            <div className="single_post_heading">
                                <h1>{comunicado.titulo}</h1>
                                <div className="space-10" />
                                <p>{comunicado.subtitulo}</p>
                            </div>
                            <div className="space-40" />
                            {
                                comunicado.comunicadoMidia && comunicado.comunicadoMidia.find(item => item.funcao === "Principal") ?
                                    <img
                                        src={getMidiaUrl(comunicado.comunicadoMidia.find(item => item.funcao === "Principal").midia.chave)}
                                        alt="Imagem da Notícia" />
                                    : null
                            }
                            <div className="space-20" />
                            <div className="row">
                                <div className="col-lg-6 align-self-center">

                                </div>
                                <div className="col-lg-6 align-self-center">
                                    <div className="author_social inline text-right">
                                        <ul>
                                            <LiRedes />
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 align-self-center">
                                    <iframe
                                        title='share'
                                        src={`https://www.facebook.com/plugins/share_button.php?href=${url}&layout=button_count&size=large&appId=241476930391586&width=115&height=30`}
                                        width="115" height="30" style={{ marginTop: 10, border: "none", overflow: "hidden" }} scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

                                </div>
                                <div className="col-lg-3 align-self-center"></div>
                                <div className="col-lg-3 align-self-center"></div>
                                <div className="col-lg-3 align-self-center">
                                    <WhatsappShareButton url={url}>
                                        <WhatsappIcon size={32} round={true} />
                                        <span style={{ fontSize: 13, marginLeft: 5 }}>
                                            Enviar no Whats
                                        </span>
                                    </WhatsappShareButton>

                                </div>
                            </div>
                            <div className="space-40" />
                            {
                                comunicado.comunicadoMidia && comunicado.comunicadoMidia.find(item => item.funcao === "Superior") ?
                                    <img
                                        src={getMidiaUrl(comunicado.comunicadoMidia.find(item => item.funcao === "Superior").midia.chave)}
                                        alt="Imagem da Notícia" />
                                    : null
                            }
                            <div className="space-20" />

                            <div
                                className='post-p'
                                style={
                                    {
                                        //textAlign: "left",
                                        //fontStretch: "100%",
                                        //display: "block",
                                        fontSize: 18,
                                        color: "#535353",
                                        //verticalAlign: "initial",
                                        letterSpacing: 0,
                                        //lineHeight: "27px",
                                        fontWeight: 400,
                                        textSizeAdjust: "100%",
                                        fontFamily: "opensans,helvetica,arial,sans-serif"
                                        //textJustify: "inter-word"
                                    }
                                } dangerouslySetInnerHTML={{ __html: comunicado.texto }}></div>
                            {
                                comunicado.comunicadoMidia && comunicado.comunicadoMidia.find(item => item.funcao === "Galeria")
                                    ?
                                    <Galeria midias={comunicado.comunicadoMidia.filter(item => item.funcao === "Galeria")} />
                                    : null

                            }

                            <div className="space-10" />
                            {
                                comunicado.comunicadoMidia && comunicado.comunicadoMidia.find(item => item.funcao === "Inferior") ?
                                    <img
                                        src={getMidiaUrl(comunicado.comunicadoMidia.find(item => item.funcao === "Inferior").midia.chave)}
                                        alt="Imagem da Notícia" />
                                    : null
                            }
                            <div className="space-10" />
                            <div className="tags">

                            </div>
                            <div className="space-10" />
                            <div className="border_black" style={{ paddingTop: 10 }} />
                            <iframe
                                title='curtir'
                                src={`https://www.facebook.com/plugins/like.php?href=${url}&width=233&layout=button_count&action=like&size=large&share=true&height=46&appId=241476930391586`}
                                width="233" height="46" style={{ border: "none", overflow: "hidden" }} scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            <FacebookProvider appId="241476930391586">
                                <Comments href={url} />
                            </FacebookProvider>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <SidePage
                                patrocinios={true}
                                redes={true}
                                face={false}
                                maisLidas={false}
                                colunistas={false}
                                ultimas={true}
                                total={5}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60" />
            {
                /*
            <OurBlogSection />
            <div className="space-60" />
            <BlogComment />
            <div className="space-100" />
            <BannerSection />
                */
            }
        </Fragment >
    )
};

export default ComunicadoPage;