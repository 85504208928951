import React from 'react';
const FooterCopyright = () => {
    return (
        <div className="copyright">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 align-self-center">
                        <p>&copy; Copyright 2022, Todos os direitos reservados</p>
                    </div>
                    <div className="col-lg-6 align-self-center">
                        <div className="copyright_menus text-right">
                            <div className="language" />
                            <div className="copyright_menu inline">
                                <ul>

                                    <li><a rel="noopener noreferrer" href='https://mootec.com.br/' target='_blank'>Criado por MooTec Technologies and Business</a></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterCopyright;