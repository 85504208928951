import { api } from '../settings'
import axios from 'axios';
export const SET_COMUNICADO_DATA = 'SET_COMUNICADO_DATA';
export const CLEAR_COMUNICADO_DATA = 'CLEAR_COMUNICADO_DATA';

export const get = (chave) => async (dispatch, getState) => {
    const apiService = `/open/comunicados/${chave}`
    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type: SET_COMUNICADO_DATA,
            payload: response.data
        })
        //console.log(response.data)


    } catch (error) {
        console.log(error)
    }
}
