import { api } from '../settings'
import axios from 'axios';
export const SET_COLUNAS_COLUNISTA_DATA = 'SET_COLUNAS_COLUNISTA_DATA';
export const CLEAR_COLUNAS_COLUNISTA_DATA = 'CLEAR_COLUNAS_COLUNISTA_DATA';

export const get = (limit = 10, chaveColunista, offset = 0) => async (dispatch, getState) => {
    let apiService = `/open/colunas`
    apiService += `?limit=${limit}`
    apiService += `&offset=${offset}`
    apiService += `&chaveColunista=${chaveColunista}`

    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type: SET_COLUNAS_COLUNISTA_DATA,
            payload: response.data
        })



    } catch (error) {
        console.log(error)

    }
}
