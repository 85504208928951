import React, { Fragment, Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { toast } from "react-toastify";

import * as ContatoAction from '../../store/actionsgazeta/contato.actions'


class Negocios extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ locale: "pt" });
    }

    state = {
        nome: '',
        assunto: '',
        email: '',
        telefone: '',
        mensagem: '',
    };
    changeHandler = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };
    submitHandler = async (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            ContatoAction.send(this.state)
                .then(() => {
                    toast.success('Sua Mensagem foi enviada com Sucesso!')
                    this.setState({
                        nome: '',
                        assunto: '',
                        email: '',
                        telefone: '',
                        mensagem: '',
                    });
                    this.validator.hideMessages()
                }).catch(() => {

                    toast.error('Algo deu errado. Tente novamente!');
                })

        } else {
            toast.error('Informe todos os dados do formulário');
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    render() {
        return (
            <Fragment>
                <div className="contact_form padding-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <div className="space-30" />
                                <div className="single_post_heading">
                                    <h1>{'Grupo Gazeta Negócios'}</h1>
                                    <div className="space-10" />
                                    <p>{''}</p>
                                </div>
                                <div className="space-40" />
                                <div
                                    className='post-p'
                                    style={
                                        {
                                            //textAlign: "left",
                                            //fontStretch: "100%",
                                            //display: "block",
                                            fontSize: 18,
                                            color: "#535353",
                                            //verticalAlign: "initial",
                                            letterSpacing: 0,
                                            //lineHeight: "27px",
                                            fontWeight: 400,
                                            textSizeAdjust: "100%",
                                            fontFamily: "opensans,helvetica,arial,sans-serif"
                                            //textJustify: "inter-word"
                                        }
                                    } dangerouslySetInnerHTML={{
                                        __html: `Esse recado é pra você, pequeno e médio empreendedor de Carazinho e região.<b> Sabia que a sua marca pode estar no Grupo Gazeta?</b> Além de mais de 45 anos de credibilidade dos nossos veículos de comunicação, você ainda conta com um atendimento pronto pra te ajudar a definir a melhor estratégia de anúncio para o seu negócio. Seja nas nossas rádios, site ou redes sociais. Venha ser um parceiro do Grupo Gazeta e aumente a visibilidade da sua marca. Preencha o formulário abaixo ou chame no whatsapp <a href="https://wa.me/5554991559016">(54) 9 9155.9016</a> e simule agora mesmo a sua campanha!`
                                    }}></div>


                                <div className="space-10" />
                                <div className="border_black" style={{ paddingTop: 10 }} />

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <iframe title='Formulário' src="https://docs.google.com/forms/d/e/1FAIpQLSdhzzYwiH5RCOCj-L449-hO4wS19R3q70GNVHaZ0CR6TKLgDA/viewform?embedded=true" width="640" height="3331" frameborder="0" marginheight="0" marginwidth="0">Carregando…</iframe>
                            </div>
                        </div>
                        <div className="space-50" />
                    </div>
                </div>
            </Fragment >
        )
    }
}

export default Negocios;