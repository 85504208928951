import * as Actions from '../actionsgazeta';

const initialState = [];

const comunicados = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_COMUNICADOS_CAPA_DATA:
            {
                return action.payload

            }
        case Actions.CLEAR_COMUNICADOS_CAPA_DATA:
            {
                return state;
            }
        default:
            {
                return state
            }
    }
};

export default comunicados;
