import { api } from '../settings'
import axios from 'axios';
export const SET_CATEGORIA_NOTICIA_DATA = 'SET_CATEGORIA_NOTICIA_DATA';
export const CLEAR_CATEGORIA_NOTICIA_DATA = 'CLEAR_CATEGORIA_NOTICIA_DATA';

export const get = (chaveCategoria, limit = 10, offSet = 0) => async (dispatch, getState) => {
    let apiService = `/open/noticias`
    apiService += `?limit=${limit}`
    apiService += `&offset=${offSet}`
    apiService += `&chaveCategoria=${chaveCategoria}`

    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type: SET_CATEGORIA_NOTICIA_DATA,
            payload: response.data
        })

    } catch (error) {
        console.log(error)
    }
}
