import { api } from '../settings'
import axios from 'axios';
export const SET_NOTICIAS_CAPA_DATA = 'SET_NOTICIAS_CAPA_DATA';
export const CLEAR_NOTICIAS_CAPA_DATA = 'CLEAR_NOTICIAS_CAPA_DATA';

export const SET_NOTICIAS_MAISLIDAS_DATA = 'SET_NOTICIAS_MAISLIDAS_DATA';
export const CLEAR_NOTICIAS_MAISLIDAS_DATA = 'CLEAR_NOTICIAS_MAISLIDAS_DATA';


export const get = (limit = 10, tipo = "Normal", midia = false) => async (dispatch, getState) => {
    let apiService = `/open/noticias`
    apiService += `?limit=${limit}`
    if (tipo === "MaisLidas") apiService += `&maisLidasSemana=${true}`

    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type:
                tipo === "Normal" ? SET_NOTICIAS_CAPA_DATA :
                    tipo === "MaisLidas" ? SET_NOTICIAS_MAISLIDAS_DATA : null
            ,
            payload: response.data
        })

    } catch (error) {
        console.log(error)

    }
}
