import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ico670logo from '../../doc/img/logo/ico670logo.png';
import ico1003logo from '../../doc/img/logo/ico1003logo.png';
import FontAwesome from "../uiStyle/FontAwesome";
import * as RadioAction from '../../store/actionsgazeta/radio.actions'
import { isMobile } from 'react-device-detect';

const LogoArea = ({ className, dark }) => {

    const radio = useSelector(state => state.gazeta.radio)
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        var audio = document.getElementById('stream');
        audio.volume = 0.5;
    }, []);


    return (
        <div className={`logo_area ${className ? className : ''}`}>
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-lg-7">
                        {isMobile ?
                            <div
                                onClick={() => dispatch(RadioAction.close())}
                                style={{
                                    cursor: "pointer",
                                    float: "right"
                                }}
                            >
                                <FontAwesome name="close" style={{ float: "right" }} />
                            </div>
                            : null}
                        {radio === "FM" ?
                            <audio id="stream" controls preload="none" autoPlay={true}
                                style={{
                                    width: "100%",
                                    height: 40,
                                    marginTop: 7
                                }}>
                                <source src={"https://radio.hostspry.com.br/8002/stream"} type="audio/mpeg" />
                            </audio>

                            : radio === "AM" ?
                                <audio id="stream" controls preload="none" autoPlay={true}
                                    style={{
                                        width: "100%",
                                        height: 40,
                                        marginTop: 7
                                    }}>
                                    <source src={"https://radio.hostspry.com.br/8006/stream"} type="audio/mpeg" />
                                </audio>

                                : null}

                    </div>
                    {!isMobile ?
                        <div className="col-md-7 col-lg-3"
                            style={{
                                textAlign: "right"
                            }}
                        >
                            <img src={radio === "FM" ? ico1003logo : ico670logo} alt="logo" style={{
                                height: 30,
                                marginTop: 15
                            }} />
                        </div>
                        : null}
                    {!isMobile ?
                        <div className="col-md-1 col-lg-1">
                            <div
                                onClick={() => dispatch(RadioAction.close())}
                                style={{
                                    cursor: "pointer",
                                    textAlign: "left"
                                }}
                            >
                                <FontAwesome name="close" />
                            </div>
                        </div>
                        : null}
                </div>
            </div>
        </div>
    );
};

export default LogoArea;