import { api } from '../settings'
import axios from 'axios';

export const SET_PROPAGANDAS_CAPA_DATA = 'SET_PROPAGANDAS_CAPA_DATA';
export const CLEAR_PROPAGANDAS_CAPA_DATA = 'CLEAR_PROPAGANDAS_CAPA_DATA';

export const get = (limit = 20) => async (dispatch, getState) => {
    let apiService = `/open/ultimaspropagandas`
    apiService += `?limit=${limit}`

    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        //console.log(response.data);
        return dispatch({
            type: SET_PROPAGANDAS_CAPA_DATA,
            payload: response.data
        })

    } catch (error) {
        console.log(error)
    }
}
