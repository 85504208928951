export const SET_RADIO_DATA = 'SET_RADIO_DATA';
export const CLEAR_RADIO_DATA = 'CLEAR_RADIO_DATA';

export const set = (param) => async (dispatch, getState) => {
    try {
        return dispatch({
            type: SET_RADIO_DATA,
            payload: param
        })
        //console.log(response.data)

    } catch (error) {
        console.log(error)
    }
}


export const close = () => async (dispatch, getState) => {
    try {
        return dispatch({
            type: CLEAR_RADIO_DATA,
        })

    } catch (error) {
        console.log(error)
    }
}
