import React, { Fragment, useLayoutEffect, useState } from 'react';
import Galeria from "../../components/Galeria";
import LiRedes from "../../components/LiRedes";
import * as SecaoAction from '../../store/actionsgazeta/secao.actions'
import { useDispatch, useSelector } from "react-redux";
import { MetaTags } from 'react-meta-tags';
import { FacebookIcon, WhatsappShareButton, WhatsappIcon } from "react-share";
import { FacebookProvider, Comments, Share, Like } from 'react-facebook';
import SidePage from "../../components/SidePage";
import { getMidiaUrl, production } from '../../store/settings'
import logo from '../../doc/img/logo/icon.png';

const NoticiaPage = (props) => {
    const secao = useSelector(state => state.gazeta.secao)
    const dispatch = useDispatch();
    const [url, setUrl] = useState("")

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        if (props.match.params.chave) {
            let { chave } = props.match.params
            if (production)
                setUrl(`https://gazeta670.com.br/secao/${chave}`)
            else
                setUrl(`https://gazeta670.com.br/`)
            dispatch(SecaoAction.get(chave))
        }

    }, [props.match.params, dispatch]);


    return (
        <Fragment>
            <MetaTags>
                <title>{secao.titulo}</title>
                <meta name="description" content={''} />
                <meta property="og:title" content={secao.titulo} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />
                <meta property="og:image:type" content="image/jpeg" />
                <meta property="og:image:width" content="400" />
                <meta property="og:image:height" content="300" />
                <meta property="og:image:alt" content="" />

            </MetaTags>

            <div className="archives post post1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            <div className="space-30" />
                            <div className="single_post_heading">
                                <h1>{secao.titulo}</h1>
                                <div className="space-10" />
                                <p>{secao.subTitulo}</p>
                            </div>
                            <div className="space-40" />
                            {
                                secao.secaoMidia && secao.secaoMidia.find(item => item.funcao === "Principal") ?
                                    <img
                                        src={getMidiaUrl(secao.secaoMidia.find(item => item.funcao === "Principal").midia.chave)}
                                        alt="Imagem da Notícia" />
                                    : null
                            }
                            <div className="space-20" />
                            <div className="row">
                                <div className="col-lg-6 align-self-center">
                                    <div className="author">
                                        <div className="author_img">
                                            <div className="author_img_wrap">
                                                <img src={logo} alt="author2" />
                                            </div>
                                        </div>
                                        <ul>
                                            <li> Siga-nos nas redes sociais e fique por dentro de todas as nossas ações.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 align-self-center">
                                    <div className="author_social inline text-right">
                                        <ul>
                                            <LiRedes />

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 align-self-center">
                                    <FacebookProvider
                                        appId="241476930391586"
                                    >
                                        <Share
                                            href={url}>
                                            {({ handleClick, loading }) => (
                                                <div
                                                    disabled={loading}
                                                    onClick={handleClick}
                                                    style={{
                                                        cursor: "pointer",
                                                        marginTop: 10,
                                                        backgroundColor: "#3b5998",
                                                        width: "100%",
                                                        borderRadius: 10,
                                                        paddingLeft: 10,
                                                        height: 35,
                                                        color: "white",
                                                        fontSize: 13,
                                                        paddingTop: 3
                                                    }}
                                                >
                                                    <FacebookIcon size={32} /> Compartilhar
                                                </div>
                                            )}
                                        </Share>
                                    </FacebookProvider>
                                </div>
                                <div className="col-lg-3 align-self-center"></div>
                                <div className="col-lg-3 align-self-center"></div>
                                <div className="col-lg-3 align-self-center">
                                    <WhatsappShareButton url={url}>
                                        <WhatsappIcon size={32} round={true} />
                                        <span style={{ fontSize: 13, marginLeft: 5 }}>
                                            Enviar no Whats
                                        </span>
                                    </WhatsappShareButton>

                                </div>
                            </div>
                            <div className="space-40" />
                            {
                                secao.secaoMidia && secao.secaoMidia.find(item => item.funcao === "Superior") ?
                                    <img
                                        src={getMidiaUrl(secao.secaoMidia.find(item => item.funcao === "Superior").midia.chave)}
                                        alt="Imagem da Notícia" />
                                    : null
                            }
                            <div className="space-20" />

                            <div
                                className='post-p'
                                style={
                                    {
                                        //textAlign: "left",
                                        //fontStretch: "100%",
                                        //display: "block",
                                        fontSize: 18,
                                        color: "#535353",
                                        //verticalAlign: "initial",
                                        letterSpacing: 0,
                                        //lineHeight: "27px",
                                        fontWeight: 400,
                                        textSizeAdjust: "100%",
                                        fontFamily: "opensans,helvetica,arial,sans-serif"
                                        //textJustify: "inter-word"
                                    }
                                } dangerouslySetInnerHTML={{ __html: secao.texto }}></div>

                            {
                                secao.secaoMidia && secao.secaoMidia.find(item => item.funcao === "Galeria")
                                    ?
                                    <Galeria midias={secao.secaoMidia.filter(item => item.funcao === "Galeria")} />
                                    : null

                            }

                            <div className="space-10" />
                            {
                                secao.secaoMidia && secao.secaoMidia.find(item => item.funcao === "Inferior") ?
                                    <img
                                        src={getMidiaUrl(secao.secaoMidia.find(item => item.funcao === "Inferior").midia.chave)}
                                        alt="Imagem da Notícia" />
                                    : null
                            }

                            <div className="space-10" />
                            <div className="border_black" style={{ paddingTop: 10 }} />
                            <FacebookProvider
                                appId="241476930391586"
                            >
                                <Like href={url}

                                    colorScheme="dark" showFaces


                                />
                            </FacebookProvider>
                            <FacebookProvider appId="123456789">
                                <Comments href={url} />
                            </FacebookProvider>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <SidePage
                                patrocinios={true}
                                redes={false}
                                face={false}
                                maisLidas={false}
                                colunistas={false}
                                ultimas={true}
                                secao={secao}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60" />
            {
                /*
            <OurBlogSection />
            <div className="space-60" />
            <BlogComment />
            <div className="space-100" />
            <BannerSection />
                */
            }
        </Fragment >
    )
};

export default NoticiaPage;