import React from 'react';
const BreadCrumb = (props) => {
    const { className = ''} = props;
    return (
        <div className={`inner_table ${className}`}>
            <div className="container">
                {
                    /*
                <div className="row">
                    <div className="col-12">
                        <div className="bridcrumb"><Link to="/">Capa</Link> / {title}</div>
                    </div>
                </div>

                    */
                }
                {props.children}
            </div>
        </div>
    );
};

export default BreadCrumb;