import axios from 'axios';
import moment from 'moment'
import 'moment/locale/pt-br'  // without this line it didn't work
moment.locale('pt-br');


export const SET_PREVISAO_DATA = 'SET_PREVISAO_DATA';
export const CLEAR_PREVISAO_DATA = 'CLEAR_PREVISAO_DATA';


/**
 * Set User Data
 */
export const get = () => async (dispatch, getState) => {
    const apiService = "https://apiprevmet3.inmet.gov.br/previsao/4304705"
    const request = axios.get(`${apiService}`);
    request.then((response) => {
        let dados = response.data['4304705']
        let dia = dados[moment().format("L")]
        let momento
        let horas = moment().format("HH")
        if (horas < 12) momento = dia['manha']
        else if (horas < 18) momento = dia['tarde']
        else momento = dia['noite']
        return dispatch({
            type: SET_PREVISAO_DATA,
            payload: momento
        })
    }).catch((erro) => {
        if (erro.response)
            console.log(erro.response.data.error)
        else
            console.log("Servidor Offline!")

        return dispatch({
            type: CLEAR_PREVISAO_DATA
        })

        /*
        return dispatch(AlertActions.setAlertData({
            type: 'error',
            message
        }));
        */
    })
}
