import React, { Fragment } from 'react';
import MixCarousel from "../../components/MixCarousel";
import MaisNoticias from "../../components/MaisNoticias";
import Colunistas from "../../components/Colunistas";
import Destaques from "../../components/Destaques";
import SidePage from "../../components/SidePage";
import { useSelector, } from "react-redux";
import { MetaTags } from 'react-meta-tags';
import Programas from '../../components/Programas';
import { title_global, description_global } from '../../store/variables';
import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux'
import * as ColunasAction from '../../store/actionsgazeta/colunas.actions'
import * as ComunicadosAction from '../../store/actionsgazeta/comunicados.actions'

import * as ProgramasAction from '../../store/actionsgazeta/programas.actions'
import * as NoticiasAction from '../../store/actionsgazeta/noticias.actions'
import { getMidiaUrlPropaganda } from '../../store/settings';

const HomePage = () => {
    const propagandas = useSelector(state => state.gazeta.propagandas)
    const dispatch = useDispatch()

    useLayoutEffect(() => {
        dispatch(NoticiasAction.get(15, "Normal", true))
        dispatch(ColunasAction.get(5))
        dispatch(ComunicadosAction.get(5))
        dispatch(ProgramasAction.get())

    }, [dispatch])

    return (
        <Fragment>
            <MetaTags>
                <title>{title_global}</title>
                <meta name="description" content={description_global} />
                <meta property="og:title" content={title_global} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={"https://gazeta670.com.br"} />
            </MetaTags>
            {
                /*
                    <PostCarousel className="fifth_bg"/>
                    <PostGallery className="fifth_bg"/>
                    <NoticiasPrincipais className="fifth_bg" />
                */
            }
            <Destaques />
            <Programas />
            <div className="mb40 col-lg-12">
                <div className="clever-core-ads"></div>
            </div>

            {
                //<FeatureNews />
            }

            <div className="entertrainments">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="banner_area mt50 mb60 xs-mt60">
                                {
                                    propagandas.find(propaganda => propaganda.funcao === "TopNews") ?
                                        <a rel="noopener noreferrer" target="_blank" href={propagandas.find(propaganda => propaganda.funcao === "TopNews").link}>
                                            <img src={getMidiaUrlPropaganda(propagandas.find(propaganda => propaganda.funcao === "TopNews").chave)} alt="banner" />
                                        </a>
                                        : null
                                }
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="heading">
                                        <h2 className="widget-title">Mais Notícias</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="entertrainment_carousel mb30">
                                <div className="entertrainment_item">
                                    <div className="row justify-content-center">
                                        <MaisNoticias />
                                    </div>
                                </div>
                            </div>
                            <div className="banner_area mt50 mb60 xs-mt60">
                                {
                                    propagandas.find(propaganda => propaganda.funcao === "TopColunistas") ?
                                        <a rel="noopener noreferrer" target="_blank" href={propagandas.find(propaganda => propaganda.funcao === "TopColunistas").link}>
                                            <img src={getMidiaUrlPropaganda(propagandas.find(propaganda => propaganda.funcao === "TopColunistas").chave)} alt="banner" />
                                        </a>
                                        : null
                                }
                            </div>
                            <div className="border_black" style={{ marginBottom: 15 }} />

                            <Colunistas />
                        </div>
                        <div className="col-lg-4">
                            <SidePage
                                patrocinios={true}
                                redes={false}
                                face={false}
                                maisLidas={true}
                                total={5}
                                ultimas={false}
                                colunistas={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <MixCarousel className="half_bg1" />
        </Fragment>
    );
};

export default HomePage;