import { combineReducers } from 'redux';
import previsao from './previsao.reducer';
import secoes from './secoes.reducer';
import secao from './secao.reducer';
import categorias from './categorias.reducer';
import noticiasCapa from './noticiasCapa.reducer';
import noticiasMaisLidas from './noticiasMaisLidas.reducer';
import colunasCapa from './colunasCapa.reducer';
import colunistas from './colunistas.reducer';
import noticia from './noticia.reducer';
import coluna from './coluna.reducer';
import categoriaNoticias from './categoriaNoticias.reducer';
import comunicado from './comunicado.reducer';
import comunicados from './comunicados.reducer';
import colunasColunista from './colunasColunista.reducer';
import propagandas from './propagandas.reducer';
import radio from './radio.reducer';
import pesquisa from './pesquisa.reducer';
import programas from './programas.reducer';
import programaItens from './programaItens.reducer';



const gazeta = combineReducers({
    previsao,
    secoes,
    secao,
    categorias,
    colunistas,
    noticiasCapa,
    noticiasMaisLidas,
    colunasCapa,
    noticia,
    coluna,
    categoriaNoticias,
    comunicados,
    comunicado,
    colunasColunista,
    propagandas,
    radio,
    pesquisa,
    programas,
    programaItens
});

export default gazeta;