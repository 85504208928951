import { api } from '../settings'
import axios from 'axios';
export const SET_COLUNISTAS_DATA = 'SET_COLUNISTAS_DATA';
export const CLEAR_COLUNISTAS_DATA = 'CLEAR_COLUNISTAS_DATA';

export const SET_COLUNISTAS_MIDIAS = 'SET_COLUNISTAS_MIDIAS';
export const CLEAR_COLUNISTAS_MIDIAS = 'CLEAR_COLUNISTAS_MIDIAS';


export const get = () => async (dispatch, getState) => {
    const apiService = `/open/colunista`
    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type: SET_COLUNISTAS_DATA,
            payload: response.data
        })

    } catch (error) {
        console.log(error)
    }
}
