import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import erroImg from '../../doc/img/logo/logo.png';

const NotFoundPage = () => {
    return (
        <Fragment>
            <div className="inner_table">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="space-50" />
                            <div className="area404 text-center">
                                <img src={erroImg} alt="404" width={400} />
                            </div>
                            <div className="space-30" />
                            <div className="back4040 text-center col-lg-6 m-auto">
                                <h3>Ops, Página não encontrada!</h3>
                                <div className="space-10" />
                                <p>
                                    Desculpem-nos pelo transtorno. Por algum motivo essa página não existe ou foi removida. Em caso de dúvidas, contate-nos através do botão abaixo:
                                </p>
                                <div className="space-20" />
                                <div className="button_group">
                                    <Link to="/contato" className="cbtn3">Contate-nos</Link>
                                    <Link to="/" className="cbtn2">Voltar para a Capa</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space-50" />
            </div>
        </Fragment>
    )
};

export default NotFoundPage;