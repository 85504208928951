import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const FooterNewsCategories = () => {
    const categorias = useSelector(state => state.gazeta.categorias)


    return (
        <Fragment>
            <h3 className="widget-title2">Categorias</h3>
            <div className="row">
                <div className="col-lg-6">
                    <ul>
                        {categorias.map((item, key) => {
                            let total = categorias.length
                            if (key < total / 2)
                                return <li key={key}><Link to={`/categoria/${item.chave}`}>{item.nome}</Link></li>
                            else
                                return null
                        })}
                    </ul>
                </div>
                <div className="col-lg-6">
                    <ul>
                        {categorias.map((item, key) => {
                            let total = categorias.length
                            if (key > total / 2)
                                return <li key={key}><Link to={`/categoria/${item.chave}`}>{item.nome}</Link></li>
                            else
                                return null
                        })}
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default FooterNewsCategories;