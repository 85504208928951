import React from 'react';
//import FontAwesome from "../uiStyle/FontAwesome";
//import appgoogle from '../../doc/img/apps/appgoogle.jpg';
//import appstore from '../../doc/img/apps/appstore.jpg';
import youtube from '../../doc/img/apps/youtube.jpg';
import soundcloud from '../../doc/img/apps/soundcloud.jpg';

const TwitterFeed = () => {
    return (
        <div className="twitter_feeds">
            <h3 className="widget-title2">Aplicativos</h3>

            <div className="single_twitter_feed border_white_bottom">
                {
                    /*
                <a href='https://play.google.com/store/apps/details?id=br.com.mobradio.radio_gazeta&hl=pt-br' target='_blank'>
                    <img src={appgoogle} alt="banner" />
                </a>
                <div className="space-20" />
                <a href='https://play.google.com/store/apps/details?id=br.com.mobradio.radio_gazeta&hl=pt-br' target='_blank'>
                    <img src={appstore} alt="banner" />
                </a>
                    */
                }
                <div className="space-20" />
                <a rel="noopener noreferrer" href='https://www.youtube.com/channel/UCA9EDjKp3QIFtQO3OOHadSw' target='_blank'>
                    <img src={youtube} alt="banner" />
                </a>
                <div className="space-20" />
                <a rel="noopener noreferrer" href='https://soundcloud.com/grupogazeta' target='_blank'>
                    <img src={soundcloud} alt="banner" />
                </a>
            </div>
        </div>
    );
};

export default TwitterFeed;