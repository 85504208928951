import React from 'react';
import FontAwesome from "../uiStyle/FontAwesome";

const FollowUs = ({ className = '', title }) => {
    return (
        <div className={`follow_box widget mb30 ${className}`} style={{ paddingLeft: 35 }}>
            <h2 className="widget-title">{title}</h2>
            <div className="social_shares">
                <a className="single_social" style={{ backgroundColor: "#ff5500", width: 300, lineHeight: 1.1 }}
                    rel="noopener noreferrer" href="https://soundcloud.com/grupogazeta" target='_blank'>
                    <div style={{ paddingLeft: 30 }}>
                        <span className="follow_icon" style={{ fontSize: 30, height: 60, width: 60, top: "40%" }}>
                            <FontAwesome name="soundcloud" />
                        </span>
                        Acesse o SoundCloud
                        <span className="icon_text">
                            e confira o Podcast da Gazeta
                        </span>
                    </div>
                </a>
            </div>
        </div>
    );
};

export default FollowUs;