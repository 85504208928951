import React, { Fragment, useLayoutEffect, useState } from 'react';
import Galeria from "../../components/Galeria";
import moment from 'moment';
import user from '../../doc/img/header/user.png';
import * as ColunaAction from '../../store/actionsgazeta/coluna.actions'
import { useDispatch, useSelector } from "react-redux";
import { MetaTags } from 'react-meta-tags';
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { FacebookProvider, Comments } from 'react-facebook';
import SidePage from "../../components/SidePage";
import { production } from '../../store/settings'
import LiRedes from "../../components/LiRedes";
import { getMidiaUrl } from '../../store/settings';

const ColunaPage = (props) => {
    const coluna = useSelector(state => state.gazeta.coluna)
    const dispatch = useDispatch();
    const [url, setUrl] = useState("")

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        if (props.match.params.chave) {
            let { chave } = props.match.params
            dispatch(ColunaAction.get(chave))
            if (production)
                setUrl(`https://gazeta670.com.br/coluna/${chave}`)
            else
                setUrl(`https://gazeta670.com.br/`)
        }

    }, [props.match.params, dispatch]);


    return (
        <Fragment>
            <MetaTags>
                <title>{coluna.titulo}</title>
            </MetaTags>
            <div className="archives post post1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            <div className="space-30" />
                            <div className="single_post_heading">
                                <h1>{coluna.titulo}</h1>
                                <div className="space-10" />
                                <p>{coluna.subTitulo}</p>
                            </div>
                            <div className="space-40" />
                            {
                                coluna.colunaMidia && coluna.colunaMidia.find(item => item.funcao === "Principal") ?
                                    <img
                                        src={getMidiaUrl(coluna.colunaMidia.find(item => item.funcao === "Principal").midia.chave)}
                                        alt="Imagem da Coluna" />
                                    : null
                            }

                            <div className="space-20" />
                            <div className="row">
                                <div className="col-lg-6 align-self-center">
                                    <div className="author">

                                        <div className="author_img">
                                            <div className="author_img_wrap">
                                                {
                                                    coluna.colunista && coluna.colunista.colunistaMidia && coluna.colunista.colunistaMidia.find(itemColunista => itemColunista.funcao === "Perfil") ?
                                                        <img
                                                            src={getMidiaUrl(coluna.colunista.colunistaMidia.find(itemColunista => itemColunista.funcao === "Perfil").midia.chave)} alt="Foto Colunista" />
                                                        : <img src={user} alt="author2" />
                                                }
                                            </div>
                                        </div>

                                        Publicado por {coluna.colunista ? coluna.colunista.nome : null}
                                        <ul>
                                            <li>
                                                {moment(coluna.data, 'YYYY-MM-DDTHH:mm.Z').format("DD/MM/YYYY HH:mm")}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 align-self-center">
                                    <div className="author_social inline text-right">
                                        <ul>
                                            <LiRedes />
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 align-self-center">
                                    <iframe
                                        title="share"
                                        src={`https://www.facebook.com/plugins/share_button.php?href=${url}&layout=button_count&size=large&appId=241476930391586&width=115&height=30`}
                                        width="115" height="30" style={{ marginTop: 10, border: "none", overflow: "hidden" }} scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>

                                </div>
                                <div className="col-lg-3 align-self-center"></div>
                                <div className="col-lg-3 align-self-center"></div>
                                <div className="col-lg-3 align-self-center">
                                    <WhatsappShareButton url={url}>
                                        <WhatsappIcon size={32} round={true} />
                                        <span style={{ fontSize: 13, marginLeft: 5 }}>
                                            Enviar no Whats
                                        </span>
                                    </WhatsappShareButton>

                                </div>
                            </div>
                            <div className="space-40" />
                            {
                                coluna.colunaMidia && coluna.colunaMidia.find(item => item.funcao === "Superior") ?
                                    <img
                                        src={getMidiaUrl(coluna.colunaMidia.find(item => item.funcao === "Superior").midia.chave)}
                                        alt="Imagem da Coluna" />
                                    : null
                            }

                            <div className="space-20" />

                            <div
                                className='post-p'
                                style={
                                    {
                                        //textAlign: "left",
                                        //fontStretch: "100%",
                                        //display: "block",
                                        fontSize: 18,
                                        color: "#535353",
                                        //verticalAlign: "initial",
                                        letterSpacing: 0,
                                        //lineHeight: "27px",
                                        fontWeight: 400,
                                        textSizeAdjust: "100%",
                                        fontFamily: "opensans,helvetica,arial,sans-serif"
                                        //textJustify: "inter-word"
                                    }
                                } dangerouslySetInnerHTML={{ __html: coluna.texto }}></div>

                            {
                                coluna.colunaMidia && coluna.colunaMidia.find(item => item.funcao === "Galeria")
                                    ?
                                    <Galeria midias={coluna.colunaMidia.filter(item => item.funcao === "Galeria")} />
                                    : null

                            }

                            <div className="space-40" />
                            {
                                coluna.colunaMidia && coluna.colunaMidia.find(item => item.funcao === "Inferior") ?
                                    <img
                                        src={getMidiaUrl(coluna.colunaMidia.find(item => item.funcao === "Inferior").midia.chave)}
                                        alt="Imagem da Coluna" />
                                    : null
                            }
                            <div className="space-20" />
                            <div className="space-10" />
                            <div className="border_black" style={{ paddingTop: 10 }} />
                            <iframe
                                title='curtir'
                                src={`https://www.facebook.com/plugins/like.php?href=${url}&width=233&layout=button_count&action=like&size=large&share=true&height=46&appId=241476930391586`}
                                width="233" height="46" style={{ border: "none", overflow: "hidden" }} scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            <FacebookProvider appId="241476930391586">
                                <Comments href={url} />
                            </FacebookProvider>
                            <div className="border_black" />
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <SidePage
                                patrocinios={true}
                                redes={false}
                                face={false}
                                maisLidas={false}
                                colunistas={false}
                                ultimasColunas={true}
                                ultimas={false}
                            />

                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60" />
            {
                /*
            <OurBlogSection />
            <div className="space-60" />
            <BlogComment />
            <div className="space-100" />
            <BannerSection />
                */
            }
        </Fragment >
    )
};

export default ColunaPage;