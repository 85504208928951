import React, { useLayoutEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { getMidiaUrl } from '../../store/settings';

const Destaques = () => {
    const noticias = useSelector(state => state.gazeta.noticiasCapa)
    const [noticia1, setNoticia1] = useState({})
    const [noticia2, setNoticia2] = useState({})
    const [noticia3, setNoticia3] = useState({})

    useLayoutEffect(() => {
        // se injetar direto a MaterialTable buga o redux
        let dados = noticias.map((item, key) => {
            var now = moment(new Date()); //todays date
            var end = moment(item.data, 'YYYY-MM-DDTHH:mm.Z'); // another date
            var duration = moment.duration(now.diff(end));
            var diferencaMin = duration.asMinutes();
            let tempo = ""
            if (diferencaMin < 60) tempo = `Há ${parseInt(diferencaMin)} ${parseInt(diferencaMin) > 1 ? "minutos" : "minuto"} `
            else if (diferencaMin < 1440) tempo = `Há ${parseInt(diferencaMin / 60)} ${parseInt(diferencaMin / 60) > 1 ? "horas" : "hora"}`
            else if (diferencaMin > 1440) tempo = `Há ${parseInt(diferencaMin / 1440)} ${parseInt(diferencaMin / 1440) > 1 ? "dias" : "dia"}`
            return Object.assign(
                {},
                item,
                { tempo },
                { data: moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("DD/MM/YYYY HH:mm") },
                { link: `/noticia/${moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("YYYY")}/${moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("MM")}/${moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("DD")}/${item.chave}` }
            )
        })
        Promise.all(dados).then(values => {
            if (values.length > 0) setNoticia1(values[0])
            if (values.length > 1) setNoticia2(values[1])
            if (values.length > 2) setNoticia3(values[2])
        })

    }, [noticias])


    return (
        <div className="post_gallary_area theme3_bg padding-top-30">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-xl-6">
                        {noticia1.chaveMidia ?
                            <div className="single_post post_type6 border-radious7 xs-mb30 mb30">
                                <div className="post_img gradient1">
                                    <div className="img_wrap">
                                        <Link to={`${noticia1.link}`}>
                                            <img src={getMidiaUrl(noticia1.chaveMidia)} alt="Imagem da Notícia"
                                                style={isMobile ? { height: 250 } : { minHeight: 500 }}
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="single_post_text">
                                    <h4>
                                        <Link to={`${noticia1.link}`}>
                                            {noticia1.titulo}
                                        </Link>
                                    </h4>
                                    <div className="space-5" />
                                    <div className="space-20" />
                                    <div className="meta meta_separator1">
                                        {noticia1.categorias ? noticia1.categorias.map((item, key, row) =>
                                            <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                {`${item.nome}`}
                                                {key + 1 !== row.length ? " /" : null}
                                            </Link>
                                        ) : null}
                                        <Link to={`${noticia1.link}`}>{noticia1.tempo}</Link>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="single_post post_type3 post_type15 mb30 border-radious5 sm-mt30">
                                <div className="single_post_text white_bg padding20">
                                    <div className="row" style={{ minHeight: 500 }}>
                                        <div className="col-lg-12 col-xl-12" >
                                            <div className="meta3">
                                                {noticia1.categorias ? noticia1.categorias.map((item, key, row) =>
                                                    <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                        {`${item.nome}`}
                                                        {key + 1 !== row.length ? " /" : null}
                                                    </Link>
                                                ) : null}

                                                <Link to={`${noticia1.link}`}>{noticia1.tempo}</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-xl-12">
                                            <h4>
                                                <Link to={`${noticia1.link}`} style={{ fontSize: 30, lineHeight: 1.2 }}>
                                                    {noticia1.titulo}
                                                </Link>
                                            </h4>
                                            <div className="space-10" />
                                            <p className="post-p">{noticia1.subTitulo}</p>
                                            <div className="space-20" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                    <div className="col-lg-8 col-xl-6">
                        {noticia2.chaveMidia ?
                            <div className="single_post post_type3 post_type15 mb30 border-radious5 sm-mt30">
                                <div className="single_post_text white_bg padding20">
                                    <div className="row">
                                        <div className="col-lg-5 col-xl-5">
                                            <div className="post_img">
                                                <div className="img_wrap" style={{ borderRadius: 5 }}>
                                                    <Link to={`${noticia2.link}`}>
                                                        <img
                                                            src={getMidiaUrl(noticia2.chaveMidia)} alt="Imagem da Notícia"
                                                            height={200}
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-xl-7">
                                            <h4>
                                                <Link to={`${noticia2.link}`}>
                                                    {noticia2.titulo}
                                                </Link>
                                            </h4>
                                            <div className="space-10" />
                                            <p className="post-p">{noticia2.subTitulo}</p>
                                            <div className="space-20" />
                                            <div className="meta3">
                                                {noticia2.categorias ? noticia2.categorias.map((item, key, row) =>
                                                    <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                        {`${item.nome}`}
                                                        {key + 1 !== row.length ? " /" : null}
                                                    </Link>
                                                ) : null}

                                                <Link to={`${noticia2.link}`}>{noticia2.tempo}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="single_post post_type3 post_type15 mb30 border-radious5 sm-mt30">
                                <div className="single_post_text white_bg padding20">
                                    <div className="row">
                                        <div className="col-lg-12 col-xl-12">
                                            <h4>
                                                <Link to={`${noticia2.link}`} style={{ fontSize: 30, lineHeight: 1.1 }}>
                                                    {noticia2.titulo}
                                                </Link>
                                            </h4>
                                            <div className="space-10" />
                                            <p className="post-p">{noticia2.subTitulo}</p>
                                            <div className="space-20" />
                                            <div className="meta3">
                                                {noticia2.categorias ? noticia2.categorias.map((item, key, row) =>
                                                    <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                        {`${item.nome}`}
                                                        {key + 1 !== row.length ? " /" : null}
                                                    </Link>
                                                ) : null}

                                                <Link to={`${noticia2.link}`}>{noticia2.tempo}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {noticia3.chaveMidia ?
                            <div className="single_post post_type3 post_type15 mb30 border-radious5 sm-mt30">
                                <div className="single_post_text white_bg padding20">
                                    <div className="row">
                                        <div className="col-lg-7 col-xl-7">
                                            <h4>
                                                <Link to={`${noticia3.link}`}>
                                                    {noticia3.titulo}
                                                </Link>
                                            </h4>
                                            <div className="space-10" />
                                            <p className="post-p">{noticia3.subTitulo}</p>
                                            <div className="space-20" />
                                            <div className="meta3">
                                                {noticia3.categorias ? noticia3.categorias.map((item, key, row) =>
                                                    <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                        {`${item.nome}`}
                                                        {key + 1 !== row.length ? " /" : null}
                                                    </Link>
                                                ) : null}

                                                <Link to={`${noticia3.link}`}>{noticia3.tempo}</Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-xl-5">
                                            <div className="post_img">
                                                <div className="img_wrap" style={{ borderRadius: 5 }}>
                                                    <Link to={`${noticia3.link}`}>
                                                        <img
                                                            src={getMidiaUrl(noticia3.chaveMidia)}
                                                            alt="col26"
                                                            height={200}
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="single_post post_type3 post_type15 mb30 border-radious5 sm-mt30">
                                <div className="single_post_text white_bg padding20">
                                    <div className="row">

                                        <div className="col-lg-12 col-xl-12">
                                            <h4>
                                                <Link to={`${noticia3.link}`} style={{ fontSize: 30, lineHeight: 1.1 }}>
                                                    {noticia3.titulo}
                                                </Link>
                                            </h4>
                                            <div className="space-10" />
                                            <p className="post-p">{noticia3.subTitulo}</p>
                                            <div className="space-20" />
                                            <div className="meta3">
                                                {noticia3.categorias ? noticia3.categorias.map((item, key, row) =>
                                                    <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                        {`${item.nome}`}
                                                        {key + 1 !== row.length ? " /" : null}
                                                    </Link>
                                                ) : null}
                                                <Link to={`${noticia3.link}`}>
                                                    {noticia3.tempo}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }


                    </div>

                </div>
            </div>
        </div>
    );
};

export default Destaques;