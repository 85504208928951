import React, { Fragment, useLayoutEffect, useState, useEffect } from 'react';
import * as PesquisaAction from '../../store/actionsgazeta/pesquisa.actions'
import SidePage from "../../components/SidePage";
import { useDispatch, useSelector } from "react-redux";
import { Fade, Button, TabContent, TabPane, Spinner } from "reactstrap";
import moment from 'moment';
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import FontAwesome from "../../components/uiStyle/FontAwesome";
import { getMidiaUrl } from '../../store/settings';

const PesquisaPage = (props) => {
    const [activeTab] = useState('1');
    const [campo, setCampo] = useState("")
    const [titulo, setTitulo] = useState(true)
    const [subtitulo, setSubtitulo] = useState(false)
    const [texto, setTexto] = useState(false)
    const dispatch = useDispatch();
    const pesquisa = useSelector(state => state.gazeta.pesquisa)
    const [dados, setDados] = useState([])
    const [loading, setLoading] = useState(false)
    const [recaptcha, setRecaptcha] = useState(false);
    const [offset, setOffset] = useState(0)

    useLayoutEffect(() => {
        window.scrollTo(0, 0)

    }, []);


    const changePage = async (param) => {
        window.scroll(0, 0)
        let muda = offset + param
        if (muda < 0)
            setOffset(0)
        else
            setOffset(muda)
        pesquisar(muda)
    }


    useEffect(() => {
        // se injetar direto a MaterialTable buga o redux
        let ajuste = pesquisa.map((item, key) => {
            var now = moment(new Date()); //todays date
            var end = moment(item.data, 'YYYY-MM-DDTHH:mm.Z'); // another date
            var duration = moment.duration(now.diff(end));
            var diferencaMin = duration.asMinutes();
            let tempo = ""
            if (diferencaMin < 60) tempo = `Há ${parseInt(diferencaMin)} ${parseInt(diferencaMin) > 1 ? "minutos" : "minuto"} `
            else if (diferencaMin < 1440) tempo = `Há ${parseInt(diferencaMin / 60)} ${parseInt(diferencaMin / 60) > 1 ? "horas" : "hora"}`
            else if (diferencaMin > 1440) tempo = `Há ${parseInt(diferencaMin / 1440)} ${parseInt(diferencaMin / 1440) > 1 ? "dias" : "dia"}`
            return Object.assign(
                {},
                item,
                { tempo },
                { data: moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("DD/MM/YYYY HH:mm") },
                { link: `/noticia/${moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("YYYY")}/${moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("MM")}/${moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("DD")}/${item.chave}` }
            )
        })
        Promise.all(ajuste).then(values => {
            setDados(values)
        })

    }, [pesquisa])

    const pesquisar = async (param) => {
        setLoading(true)
        let tipoPesquisa = 1
        if (subtitulo)
            tipoPesquisa = 2
        if (texto)
            tipoPesquisa = 3

        await dispatch(PesquisaAction.get(10, param, campo, tipoPesquisa))
        setLoading(false)
    }
    return (
        <Fragment>
            <div className="contact_form padding-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="cotact_form">
                                <div className="row">
                                    <div className="col-12">
                                        <h3>Preencha os campos abaixo para realizar a sua pesquisa: </h3>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div>
                                                    <input name="nome"
                                                        value={campo}
                                                        onChange={(e) => setCampo(e.target.value)}
                                                        type="text"
                                                        placeholder="Pesquisa"
                                                        style={{ marginBottom: 5 }}
                                                    />
                                                </div>
                                                <div>
                                                    <input type="checkbox" id="titulo" name="titulo"
                                                        checked={titulo}
                                                        style={{ marginRight: 5 }}
                                                        onChange={(e) => setTitulo(e.target.checked)}
                                                        disabled
                                                    />
                                                    <label htmlFor="titulo">Título</label>

                                                    <input type="checkbox" id="subtitulo" name="subtitulo"
                                                        checked={subtitulo}
                                                        onChange={(e) => setSubtitulo(e.target.checked)}
                                                        style={{ marginRight: 5, marginLeft: 5 }}
                                                    />
                                                    <label htmlFor="subtitulo">Subtítulo</label>
                                                    <input type="checkbox" id="texto" name="texto"
                                                        checked={texto}
                                                        onChange={(e) => setTexto(e.target.checked)}
                                                        style={{ marginRight: 5, marginLeft: 5 }}
                                                    />
                                                    <label htmlFor="texto">Texto</label>

                                                </div>
                                            </div>
                                            <div className="col-lg-6"></div>
                                            <div className="col-lg-12">
                                                <ReCAPTCHA
                                                    style={{ display: 'inline-block' }}
                                                    size="normal"
                                                    sitekey="6Lccz_AeAAAAAHW3xTm4f2uOAYtaLp2JMT07jRIY"
                                                    onChange={(e) => {
                                                        if (e) setRecaptcha(true)
                                                        else setRecaptcha(false)
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="space-20" />
                                                <button className="cbtn1"
                                                    disabled={campo.length === 0 || !recaptcha}
                                                    onClick={pesquisar}
                                                    style={{ backgroundColor: campo.length === 0 || !recaptcha ? "#e5e7e3" : "#1091FF" }}
                                                >Pesquisar</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border_black" style={{ marginBottom: 15, marginTop: 15 }} />
                            <div className="about_posts_tab">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Fade in={activeTab === '1'}>
                                            <div className="row justify-content-center">
                                                {loading &&
                                                    <Spinner color="primary" style={{ fontSize: 50 }}>
                                                        Loading...
                                                    </Spinner>
                                                }
                                                {dados.map((item, i) => (
                                                    <div key={i} className="col-lg-6">
                                                        {item.chaveMidia ?
                                                            <div className="single_post post_type3 mb30">
                                                                <div className="post_img">
                                                                    <div className="img_wrap">
                                                                        <Link to={`${item.link}`}>
                                                                            <img src={getMidiaUrl(item.chaveMidia)} alt="thumb" />
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                                <div className="single_post_text">
                                                                    <div className="meta3">
                                                                        {item.categorias ? item.categorias.map((item, key, row) =>
                                                                            <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                                                {`${item.nome}`}
                                                                                {key + 1 !== row.length ? " /" : null}
                                                                            </Link>
                                                                        ) : null}
                                                                        <Link to={`${item.link}`}>{item.tempo}</Link>
                                                                    </div>
                                                                    <h4><Link to={`${item.link}`}>{item.titulo}</Link></h4>
                                                                    <div className="space-10" />
                                                                    <p className="post-p">{item.subTitulo}</p>
                                                                </div>
                                                            </div>
                                                            :
                                                            <>
                                                                <div className="single_post_text" style={{ padding: 5 }}>
                                                                    <div className="meta3">
                                                                        {item.categorias ? item.categorias.map((item, key, row) =>
                                                                            <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                                                {`${item.nome}`}
                                                                                {key + 1 !== row.length ? " /" : null}
                                                                            </Link>
                                                                        ) : null}
                                                                        <Link to={`${item.link}`}>{item.tempo}</Link>
                                                                    </div>

                                                                    <h4><Link to={`${item.link}`} style={{ fontSize: 30, lineHeight: 1.2 }}>{item.titulo}</Link></h4>
                                                                    <div className="space-10" />
                                                                    <p className="post-p">{item.subTitulo}</p>
                                                                </div>

                                                            </>
                                                        }

                                                    </div>
                                                ))}
                                            </div>
                                        </Fade>
                                    </TabPane>

                                </TabContent>
                            </div>
                            {pesquisa.length > 0 &&
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cpagination">
                                            <div className="border_black" style={{ marginBottom: 15 }} />
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <Button className="page-link" aria-label="Previous"
                                                            style={{ width: 200, fontSize: 16 }}
                                                            onClick={() => changePage(-10)}
                                                        >
                                                            <span aria-hidden="true"><FontAwesome
                                                                name="caret-left" /> Voltar</span>
                                                        </Button>
                                                    </li>
                                                    <li className="page-item">
                                                        <Button className="page-link" aria-label="Next"
                                                            style={{ width: 200, fontSize: 16 }}
                                                            onClick={() => changePage(10)}
                                                        >
                                                            <span aria-hidden="true">Próxima Página
                                                                <FontAwesome
                                                                    name="caret-right"
                                                                    style={{ marginLeft: 10 }}
                                                                />
                                                            </span>
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-lg-4">
                            <SidePage
                                patrocinios={true}
                                redes={false}
                                face={false}
                                maisLidas={false}
                                colunistas={false}
                                ultimas={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    )
};
export default PesquisaPage;