import { api } from '../settings'
import axios from 'axios';
export const SET_NOTICIA_DATA = 'SET_NOTICIA_DATA';
export const CLEAR_NOTICIA_DATA = 'CLEAR_NOTICIA_DATA';

export const get = (ano, mes, dia, chave) => async (dispatch, getState) => {
    const apiService = `/open/noticias/${ano}/${mes}/${dia}/${chave}`
    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return await dispatch({
            type: SET_NOTICIA_DATA,
            payload: response.data
        })
        /*
        let midias = []
        for (const key in response.data.noticiaMidia) {
            try {
                let item = response.data.noticiaMidia[key]
                let midia = await MidiaAction.getMidia(item.midia.chave)
                midias.push({ midia, legenda: item.midia.legenda, funcao: item.funcao })
            } catch (error) { }
        }

        await dispatch({
            type: SET_NOTICIA_MIDIAS,
            payload: midias
        })

        let midiaJornalista = {}
        try {
            let item = response.data.usuario.perfil.perfilMidia.find(item => item.funcao === "Perfil")
            if (item) {
                let midia = await MidiaAction.getMidia(item.midia.chave)
                midiaJornalista = { midia }
            }
        } catch (error) { }


        return dispatch({
            type: SET_NOTICIA_MIDIA_JORNALISTA,
            payload: midiaJornalista
        })
        */
    } catch (error) {
        console.log(error)
       
    }
}


export const getOld = (id) => {
    const apiService = `/noticia?id=${id}`
    return new Promise((resolve, reject) => {
        axios.get(`${api}${apiService}`)
            .then((res) => {
                resolve(res.data)
            })
            .catch((error) => {
                if (error.response) { reject("error.response.data.error") }
                else { reject("Servidor Offline!") }
            })
    });

}
