import React, { useLayoutEffect, useState } from 'react';
import FollowUs from "../../components/FollowUs";
import { FacebookProvider, Page } from 'react-facebook';
import MaisLidas from "../../components/MaisLidas";
import UltimasColunas from "../../components/UltimasColunas";
import { useSelector } from "react-redux";
import UltimasNoticias from "../../components/UltimasNoticias";
import ColunistasPerfil from "../../components/ColunistasPerfil";

import './style.scss';
import { getMidiaUrlPropaganda } from '../../store/settings';

const SidePage = (props) => {
    const propagandas = useSelector(state => state.gazeta.propagandas)

    const [patrocinios, setPatrocinios] = useState(false)
    const [redes, setRedes] = useState(false)
    const [face, setFace] = useState(false)
    const [maisLidas, setMaisLidas] = useState(false)
    const [colunistas, setColunistas] = useState(false)
    const [noticia] = useState({})
    const [ultimas, setUltimas] = useState(false)
    const [ultimasColunas, setUltimasColunas] = useState(false)
    const [total, setTotal] = useState(10)
    useLayoutEffect(() => {
        setPatrocinios(props.patrocinios)
        setRedes(props.redes)
        setFace(props.face)
        setMaisLidas(props.maisLidas)
        setColunistas(props.colunistas)
        setUltimas(props.ultimas)
        setUltimasColunas(props.ultimasColunas)
        if (props.total)
            setTotal(props.total)

    }, [props])

    return (
        <div className="row">
            {redes ? <FollowUs title="" /> : null}
            {patrocinios ?
                <div className="col-lg-12">
                    <div className="banner2 mb30">
                        {
                            propagandas.find(propaganda => propaganda.funcao === "Side01") ?
                                <a rel="noopener noreferrer" target="_blank" href={propagandas.find(propaganda => propaganda.funcao === "Side01").link}>
                                    <img src={getMidiaUrlPropaganda(propagandas.find(propaganda => propaganda.funcao === "Side01").chave)} alt="banner" />
                                </a>
                                : null
                        }
                    </div>
                    <div className="banner2 mb30">
                        {
                            propagandas.find(propaganda => propaganda.funcao === "Side02") ?
                                <a rel="noopener noreferrer" target="_blank" href={propagandas.find(propaganda => propaganda.funcao === "Side02").link}>
                                    <img src={getMidiaUrlPropaganda(propagandas.find(propaganda => propaganda.funcao === "Side02").chave)} alt="banner" />
                                </a>
                                : null
                        }
                    </div>
                </div>
                : null}
            {
                /*
<div className="col-lg-12">
                <div className="clever-core-ads"></div>
            </div>
                */
            }

            {face ?
                <div className="col-lg-12" style={{ marginBottom: 20 }}>
                    <FacebookProvider appId="241476930391586">
                        <Page href="https://www.facebook.com/portalgazetacarazinho" tabs="timeline" />
                    </FacebookProvider>
                </div>
                : null}

            {maisLidas ?
                <div className="col-lg-12">
                    <MaisLidas title="Mais lidas" noticia={noticia} total={total} />
                </div>
                : null}


            {ultimasColunas ?
                <UltimasColunas title="Últimas Colunas" />
                : null}


            {ultimas ?
                <UltimasNoticias title="Últimas Notícias" noticia={noticia} total={total} />
                : null}

            {colunistas ?
                <div className="col-lg-12">
                    <ColunistasPerfil />
                </div>
                : null}
            {patrocinios ?
                <div className="col-lg-12">
                    <div className="banner2 mb30">
                        {
                            propagandas.find(propaganda => propaganda.funcao === "Side03") ?
                                <a rel="noopener noreferrer" target="_blank" href={propagandas.find(propaganda => propaganda.funcao === "Side03").link}>
                                    <img src={getMidiaUrlPropaganda(propagandas.find(propaganda => propaganda.funcao === "Side03").chave)} alt="banner" />
                                </a>
                                : null
                        }
                    </div>
                </div>
                : null}
            {patrocinios ?
                <div className="col-lg-12">
                    <div className="banner2 mb30">
                        {
                            propagandas.find(propaganda => propaganda.funcao === "Side04") ?
                                <a rel="noopener noreferrer" target="_blank" href={propagandas.find(propaganda => propaganda.funcao === "Side04").link}>
                                    <img src={getMidiaUrlPropaganda(propagandas.find(propaganda => propaganda.funcao === "Side04").chave)} alt="banner" />
                                </a>
                                : null
                        }
                    </div>
                </div>
                : null}
            {patrocinios ?
                <div className="col-lg-12">
                    <div className="banner2 mb30">
                        {
                            propagandas.find(propaganda => propaganda.funcao === "Side05") ?
                                <a rel="noopener noreferrer" target="_blank" href={propagandas.find(propaganda => propaganda.funcao === "Side05").link}>
                                    <img src={getMidiaUrlPropaganda(propagandas.find(propaganda => propaganda.funcao === "Side05").chave)} alt="banner" />
                                </a>
                                : null
                        }
                    </div>
                </div>
                : null}




            {
                /*
            <div className="col-lg-12">
                <NewsLetter />
            </div>
            <div className="col-lg-12">
                <CategoriesWidget />
            </div>
                */
            }
        </div>
    )

}


export default SidePage;