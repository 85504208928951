import { api } from '../settings'
import axios from 'axios';
export const SET_COLUNA_DATA = 'SET_COLUNA_DATA';
export const CLEAR_COLUNA_DATA = 'CLEAR_COLUNA_DATA';

export const SET_COLUNA_MIDIA_JORNALISTA = 'SET_COLUNA_MIDIA_JORNALISTA';
export const CLEAR_COLUNA_MIDIA_JORNALISTA = 'CLEAR_COLUNA_MIDIA_JORNALISTA';



export const get = (chave) => async (dispatch, getState) => {
    const apiService = `/open/colunas/${chave}`
    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type: SET_COLUNA_DATA,
            payload: response.data
        })
        //console.log(response.data)
    } catch (error) {
        console.log(error)

    }
}
