import React, { Fragment, useLayoutEffect, useState } from 'react';
import FontAwesome from "../../components/uiStyle/FontAwesome";
import { Link } from "react-router-dom";
import Galeria from "../../components/Galeria";
import moment from 'moment';
import user from '../../doc/img/header/user.png';
import LiRedes from "../../components/LiRedes";
import * as NoticiaAction from '../../store/actionsgazeta/noticia.actions'
import { useDispatch, useSelector } from "react-redux";
import { MetaTags } from 'react-meta-tags';
import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { FacebookProvider, Comments } from 'react-facebook';
import SidePage from "../../components/SidePage";
import { production } from '../../store/settings'
import * as NoticiasAction from '../../store/actionsgazeta/noticias.actions'
import { getMidiaUrl } from '../../store/settings';
import { getMidiaUrlPropaganda } from '../../store/settings';

const NoticiaPage = (props) => {
    const noticia = useSelector(state => state.gazeta.noticia)
    const propagandas = useSelector(state => state.gazeta.propagandas)

    const dispatch = useDispatch();
    const [url, setUrl] = useState("")

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        if (props.match.params.chave) {
            dispatch(NoticiasAction.get(10, "Normal"))
            let { ano } = props.match.params
            let { mes } = props.match.params
            let { dia } = props.match.params
            let { chave } = props.match.params
            if (production)
                setUrl(`https://gazeta670.com.br/noticia/${ano}/${mes}/${dia}/${chave}`)
            else
                setUrl(`https://gazeta670.com.br/`)
            dispatch(NoticiaAction.get(ano, mes, dia, chave))
        }

    }, [props.match.params, dispatch]);


    return (
        <Fragment>
            <MetaTags>
                <title>{noticia.titulo}</title>
            </MetaTags>
            <div className="archives post post1">
                <div className="container">
                    <div className="row">
                        <div className="mb40 col-lg-12">
                            <div className="clever-core-ads"></div>
                        </div>
                        <div className="col-md-6 col-lg-8">
                            <div className="banner_area mt50 mb60 xs-mt60">
                                {
                                    propagandas.find(propaganda => propaganda.funcao === "TopNewsInterno") ?
                                        <a rel="noopener noreferrer" target="_blank" href={propagandas.find(propaganda => propaganda.funcao === "TopNewsInterno").link}>
                                            <img src={getMidiaUrlPropaganda(propagandas.find(propaganda => propaganda.funcao === "TopNewsInterno").chave)} alt="banner" />
                                        </a>
                                        : null
                                }
                            </div>
                            <div className="space-30" />
                            <div className="single_post_heading">
                                <h1>{noticia.titulo}</h1>
                                <div className="space-10" />
                                <p>{noticia.subTitulo}</p>
                            </div>
                            <div className="space-40" />
                            {
                                noticia.noticiaMidia && noticia.noticiaMidia.find(item => item.funcao === "Principal") ?
                                    <img
                                        src={getMidiaUrl(noticia.noticiaMidia.find(item => item.funcao === "Principal").midia.chave)}
                                        alt="Imagem da Notícia" />
                                    : null
                            }
                            <div className="space-20" />
                            <div className="row">
                                <div className="col-lg-6 align-self-center">
                                    <div className="author">

                                        <div className="author_img">
                                            <div className="author_img_wrap">
                                                {noticia.usuario &&
                                                    noticia.usuario.perfil &&
                                                    noticia.usuario.perfil.perfilMidia.find(item => item.funcao === "Perfil") ?
                                                    <img src={getMidiaUrl(noticia.usuario.perfil.perfilMidia.find(item => item.funcao === "Perfil").midia.chave)} alt="Imagem do Jornalista" />
                                                    :
                                                    <img src={user} alt="author2" />
                                                }
                                            </div>
                                        </div>

                                        Publicado por {noticia.usuario ? noticia.usuario.perfil.nome : null}
                                        <ul>
                                            <li>
                                                {moment(noticia.data, 'YYYY-MM-DDTHH:mm.Z').format("DD/MM/YYYY HH:mm")}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 align-self-center">
                                    <div className="author_social inline text-right">
                                        <ul>
                                            <LiRedes />

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 align-self-center">
                                    <iframe
                                        title='Share'
                                        src={`https://www.facebook.com/plugins/share_button.php?href=${url}&layout=button_count&size=large&appId=241476930391586&width=115&height=30`}
                                        width="115" height="30" style={{ marginTop: 10, border: "none", overflow: "hidden" }} scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                </div>
                                <div className="col-lg-3 align-self-center"></div>
                                <div className="col-lg-3 align-self-center"></div>
                                <div className="col-lg-3 align-self-center">
                                    <WhatsappShareButton url={url}>
                                        <WhatsappIcon size={32} round={true} />
                                        <span style={{ fontSize: 13, marginLeft: 5 }}>
                                            Enviar no Whats
                                        </span>
                                    </WhatsappShareButton>

                                </div>
                            </div>
                            <div className="space-40" />
                            {
                                noticia.noticiaMidia && noticia.noticiaMidia.find(item => item.funcao === "Superior") ?
                                    <img
                                        src={getMidiaUrl(noticia.noticiaMidia.find(item => item.funcao === "Superior").midia.chave)}
                                        alt="Imagem da Notícia" />
                                    : null
                            }
                            <div className="space-20" />

                            <div
                                className='post-p'
                                style={
                                    {
                                        //textAlign: "left",
                                        //fontStretch: "100%",
                                        //display: "block",
                                        fontSize: 18,
                                        color: "#535353",
                                        //verticalAlign: "initial",
                                        letterSpacing: 0,
                                        //lineHeight: "27px",
                                        fontWeight: 400,
                                        textSizeAdjust: "100%",
                                        fontFamily: "opensans,helvetica,arial,sans-serif"
                                        //textJustify: "inter-word"
                                    }
                                } dangerouslySetInnerHTML={{ __html: noticia.texto }}></div>

                            {
                                noticia.noticiaMidia && noticia.noticiaMidia.find(item => item.funcao === "Galeria")
                                    ?
                                    <Galeria midias={noticia.noticiaMidia.filter(item => item.funcao === "Galeria")} />
                                    : null

                            }


                            <div className="space-10" />
                            {
                                noticia.noticiaMidia && noticia.noticiaMidia.find(item => item.funcao === "Inferior") ?
                                    <img
                                        src={getMidiaUrl(noticia.noticiaMidia.find(item => item.funcao === "Inferior").midia.chave)}
                                        alt="Imagem da Notícia" />
                                    : null
                            }

                            <div className="space-10" />
                            <div className="tags">
                                <ul className="inline">
                                    <li className="tag_list"><FontAwesome name="tag" /></li>
                                    {noticia.categorias ? noticia.categorias.map((item, key, row) =>
                                        <li key={key}>
                                            <Link to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                {`${item.nome}`}
                                            </Link>
                                        </li>
                                    ) : null}

                                </ul>
                            </div>
                            <div className="space-10" />
                            <div className="border_black" style={{ paddingTop: 10 }} />
                            <iframe
                                title='curtir'
                                src={`https://www.facebook.com/plugins/like.php?href=${url}&width=233&layout=button_count&action=like&size=large&share=true&height=46&appId=241476930391586`}
                                width="233" height="46" style={{ border: "none", overflow: "hidden" }} scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            <FacebookProvider appId="241476930391586">
                                <Comments href={url} />
                            </FacebookProvider>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <SidePage
                                patrocinios={true}
                                redes={false}
                                face={false}
                                maisLidas={false}
                                colunistas={false}
                                ultimas={true}
                                noticia={noticia}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="space-60" />
            {
                /*
            <OurBlogSection />
            <div className="space-60" />
            <BlogComment />
            <div className="space-100" />
            <BannerSection />
                */
            }
        </Fragment >
    )
};

export default NoticiaPage;