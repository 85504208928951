import React, { Fragment, useState, useLayoutEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import user from '../../doc/img/header/user.png';
import { getMidiaUrl } from '../../store/settings';


const UpcomingMatches = ({ dark }) => {

    const colunistas = useSelector(state => state.gazeta.colunistas)
    const [dados, setDados] = useState([])

    useLayoutEffect(() => {
        // se injetar direto a MaterialTable buga o redux
        let ajuste = colunistas.map((item, key) => {
            return Object.assign(
                {},
                item,
                { link: `/colunista/${item.chave}` }
            )
        })
        Promise.all(ajuste).then(values => {
            setDados(values)
        })

    }, [colunistas])

    return (
        <div className="widget upcomming_macth mb30">
            <div className="row">
                <div className="col-8 align-self-center">
                    <h2 className="widget-title">Nossos Blogs</h2>
                </div>
                {
                    /*
                <div className="col-4 text-right align-self-center">
                    <Link to="#" className="see_all mb20">See All</Link>
                </div>
                    */
                }
            </div>
            {dados.map((item, i) => (
                <Fragment key={i}>
                    <div className="single_post post_type13 widgets_small">
                        <div className="post_img">
                            <Link to={`${item.link}`}>
                                {
                                    /*
 <div className="author_about" style={{ position: "initial" }} >
                                    <div className="author_img" style={{ height: 70, width: 70, lineHeight: 0 }}>
                                        <div className="author_wrap" >
                                            <img src={midias.find(midia => midia.chave === item.chave) ?
                                                midias.find(midia => midia.chave === item.chave).midia
                                                : user} alt="author1"
                                                style={{ padding: 2, height: 100, borderRadius: 0 }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                    */
                                }

                                {
                                    
                                    item.colunistaMidia && item.colunistaMidia.find(itemColunista => itemColunista.funcao === "Perfil") ?
                                        <img src={
                                            getMidiaUrl(item.colunistaMidia.find(
                                                itemColunista => itemColunista.funcao === "Perfil").midia.chave)}
                                            alt="icon"
                                            style={{ borderRadius: 10 }}
                                        />
                                        :
                                        <img src={user} alt="icon"
                                            style={{ width: "80%" }}
                                        />
                                }

                            </Link>
                        </div>
                        <div className="single_post_text">
                            <h4><Link to={`${item.link}`} className="playing_teams">
                                {item.nome}
                            </Link></h4>
                            <p style={{ fontSize: 12 }}>
                                <span> {item.email} </span>
                            </p>
                        </div>
                    </div>
                    <div className="space-10" />
                    {dark ? <div className="border_white" /> : <div className="border_black" />}
                    <div className="space-10" />
                </Fragment>
            ))}
        </div>
    );
};

export default UpcomingMatches;