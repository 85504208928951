import React, { Fragment, Component } from 'react';
import FontAwesome from "../../components/uiStyle/FontAwesome";
import SimpleReactValidator from 'simple-react-validator';
import { toast } from "react-toastify";

import scrollIcon from '../../doc/img/icon/scroll.png';
import black_phone from '../../doc/img/icon/black_phone.png';
import * as ContatoAction from '../../store/actionsgazeta/contato.actions'
import SidePage from "../../components/SidePage";

class ContactUsPage extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({ locale: "pt" });
    }

    state = {
        nome: '',
        assunto: '',
        email: '',
        telefone: '',
        mensagem: '',
    };
    changeHandler = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };
    submitHandler = async (e) => {
        e.preventDefault();
        if (this.validator.allValid()) {
            ContatoAction.send(this.state)
                .then(() => {
                    toast.success('Sua Mensagem foi enviada com Sucesso!')
                    this.setState({
                        nome: '',
                        assunto: '',
                        email: '',
                        telefone: '',
                        mensagem: '',
                    });
                    this.validator.hideMessages()
                }).catch(() => {

                    toast.error('Algo deu errado. Tente novamente!');
                })

        } else {
            toast.error('Informe todos os dados do formulário');
            this.validator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    };

    render() {
        const { nome, assunto, email, telefone, mensagem } = this.state;
        return (
            <Fragment>
                <div className="inner inner_bg inner_overlay">
                    <div className="container">
                        <div className="inner_wrap">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="title_inner">
                                        <h6>Obrigado por visitar o nosso Portal</h6>
                                        <h1>Fale Conosco</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="inner_scroll">
                                <div className="scrollIcon">
                                    <img src={scrollIcon} alt="scrollIcon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contacts section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="box single_contact_box">
                                    <div className="contact_title">
                                        <h3>Endereço</h3>
                                    </div>
                                    <div className="contact_details">
                                        <div className="contact_details_icon">
                                            <FontAwesome name="map-marker-alt" />
                                        </div>
                                        <p>Localização:</p>
                                        <h6>R. Domingos Sechi, 35 - Boa Vista, Carazinho - RS, 99500-000</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="box single_contact_box">
                                    <div className="contact_title">
                                        <h3>Contato</h3>
                                    </div>
                                    <div className="contact_details">
                                        <div className="contact_details_icon">
                                            <img src={black_phone} alt="black_phone" />
                                        </div>
                                        <p>Telefones:</p>
                                        <h6>
                                        (54) 3330 - 3143 - Central de Atendimento
                                        <br/> 
                                        (54) 99155-9016 - Contato / WhatsApp Comercial
                                        <br/>
                                        (54) 99157-1687 - WhatsApp Gazeta AM 670
                                        <br/>
                                        (54) 99129-9222 - Plantão Jornalismo
                                        <br/>
                                        (54) 99183-6300 - WhatsApp Gazeta 100.3 FM
                                            
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="box single_contact_box">
                                    <div className="contact_title">
                                        <h3>E-mails</h3>
                                    </div>
                                    <div className="contact_details">
                                        <div className="contact_details_icon">
                                            <FontAwesome name="envelope" />
                                        </div>
                                        <p>Endereços:</p>
                                        <h6>comercial@gazeta670.com.br <br /> gazeta670@gazeta670.com.br</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*contact form area*/}
                <div className="contact_form padding-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="map">
                                    <iframe
                                        title="map"
                                        frameBorder={0}
                                        height="450px"
                                        width="100%"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7338.695520482144!2d-52.7802476(54)518156!3d-28.274701370150268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94fd338f879e0e6d%3A0xdeba08d675b59ba8!2sGrupo%20Gazeta%20de%20Carazinho!5e0!3m2!1spt-BR!2sbr!4v1646142436732!5m2!1spt-BR!2sbr"
                                        allowFullScreen />
                                </div>
                            </div>
                        </div>
                        <div className="space-50" />
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="cotact_form">
                                    <div className="row">
                                        <div className="col-12">
                                            <h3>Para nos contatar <br /> utilize o formulário abaixo.</h3>
                                        </div>
                                        <div className="col-12">
                                            <form onSubmit={this.submitHandler}>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div>
                                                            <input name="nome" value={nome} onChange={this.changeHandler}
                                                                type="text"
                                                                placeholder="Nome Completo"
                                                                style={{ marginBottom: 5 }}
                                                            />
                                                            {this.validator.message('Nome Completo', nome, 'required') ?
                                                                <span style={{ color: "red", fontSize: 12 }}>Nome Completo é Obrigatório</span>
                                                                :
                                                                null}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <input name="assunto" value={assunto}
                                                            onChange={this.changeHandler} type="text"
                                                            placeholder="Assunto" 
                                                            style={{ marginBottom: 5 }}
                                                            />
                                                        {this.validator.message('Assunto', assunto, 'required') ?
                                                            <span style={{ color: "red", fontSize: 12 }}>Assunto é Obrigatório</span>
                                                            :
                                                            null}

                                                    </div>
                                                    <div className="col-lg-6">
                                                        <input name="email" value={email} onChange={this.changeHandler}
                                                            type="email"
                                                            placeholder="Email" 
                                                            style={{ marginBottom: 5 }}
                                                            />
                                                        {this.validator.message('Email', email, 'required|email') ?
                                                            <span style={{ color: "red", fontSize: 12 }}>E-mail é Obrigatório</span>
                                                            :
                                                            null}
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <input name="telefone" value={telefone} onChange={this.changeHandler}
                                                            type="number"
                                                            placeholder="Telefone (só números)" 
                                                            style={{ marginBottom: 5 }}
                                                            />
                                                        {this.validator.message('Telefone', telefone, 'required') ?
                                                            <span style={{ color: "red", fontSize: 12 }}>Telefone é Obrigatório</span>
                                                            :
                                                            null}
                                                    </div>
                                                    <div className="col-12">
                                                        <textarea name="mensagem"
                                                            value={mensagem}
                                                            onChange={this.changeHandler}
                                                            id="message"
                                                            cols="30" rows="5"
                                                            placeholder="Informe aqui a sua mensagem" 
                                                            style={{ marginBottom: 5 }}
                                                            />
                                                        {this.validator.message('Mensagem', mensagem, 'required') ?
                                                            <span style={{ color: "red", fontSize: 12 }}>Mensagem é Obrigatório</span>
                                                            :
                                                            null}
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="space-20" />
                                                        <button className="cbtn1" type="submit">Enviar Mensagem</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <SidePage
                                    patrocinios={false}
                                    redes={false}
                                    face={false}
                                    maisLidas={false}
                                    colunistas={false}
                                    ultimas={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default ContactUsPage;