import React, { Fragment, useState, useLayoutEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment';
import { getMidiaUrl } from '../../store/settings';

const EntertainmentNews = ({ entertainments }) => {

    const noticias = useSelector(state => state.gazeta.noticiasCapa)
    const [dados, setDados] = useState([])

    useLayoutEffect(() => {
        // se injetar direto a MaterialTable buga o redux
        let ajuste = noticias.slice(3, 15).map((item, key) => {
            var now = moment(new Date()); //todays date
            var end = moment(item.data, 'YYYY-MM-DDTHH:mm.Z'); // another date
            var duration = moment.duration(now.diff(end));
            var diferencaMin = duration.asMinutes();
            let tempo = ""
            if (diferencaMin < 60) tempo = `Há ${parseInt(diferencaMin)} ${parseInt(diferencaMin) > 1 ? "minutos" : "minuto"} `
            else if (diferencaMin < 1440) tempo = `Há ${parseInt(diferencaMin / 60)} ${parseInt(diferencaMin / 60) > 1 ? "horas" : "hora"}`
            else if (diferencaMin > 1440) tempo = `Há ${parseInt(diferencaMin / 1440)} ${parseInt(diferencaMin / 1440) > 1 ? "dias" : "dia"}`
            return Object.assign(
                {},
                item,
                { tempo },
                { data: moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("DD/MM/YYYY HH:mm") },
                { link: `/noticia/${moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("YYYY")}/${moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("MM")}/${moment(item.data, 'YYYY-MM-DDTHH:mm.Z').format("DD")}/${item.chave}` }
            )
        })
        Promise.all(ajuste).then(values => {
            setDados(values)
        })

    }, [noticias])

    return (
        <Fragment>
            {dados.map((item, i) => (
                <div key={i} className="col-lg-6">
                    <div className="single_post post_type3 mb30">
                        {item.chaveMidia ?
                            <>
                                <div className="single_post_text" style={{ padding: 5 }}>
                                    <div className="meta3">
                                        {item.categorias ? item.categorias.map((item, key, row) =>
                                            <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                {`${item.nome}`}
                                                {key + 1 !== row.length ? " /" : null}
                                            </Link>
                                        ) : null}
                                        <Link to={`${item.link}`}>{item.tempo}</Link>
                                    </div>
                                </div>
                                <div className="post_img">
                                    <div className="img_wrap">
                                        <Link to={`${item.link}`}>
                                            <img src={getMidiaUrl(item.chaveMidia)} alt="thumb"
                                                height={200}
                                            />
                                        </Link>
                                    </div>
                                </div>
                                <div className="single_post_text">
                                    <h4><Link to={`${item.link}`}>{item.titulo}</Link></h4>
                                    <div className="space-10" />
                                </div>
                            </>
                            :
                            <>
                                <div className="single_post_text" style={{ padding: 5 }}>
                                    <div className="meta3">
                                        {item.categorias ? item.categorias.map((item, key, row) =>
                                            <Link key={key} to={`/categoria/${item.chave}`} style={{ marginRight: 5 }}>
                                                {`${item.nome}`}
                                                {key + 1 !== row.length ? " /" : null}
                                            </Link>
                                        ) : null}
                                        <Link to={`${item.link}`}>{item.tempo}</Link>
                                    </div>

                                    <h4><Link to={`${item.link}`} style={{ fontSize: 30, lineHeight: 1.2 }}>{item.titulo}</Link></h4>
                                    <div className="space-10" />
                                    <p className="post-p">{item.subTitulo}</p>
                                </div>

                            </>
                        }
                    </div>
                </div>
            ))}
        </Fragment>
    );
};

export default EntertainmentNews;