import React, { Fragment, useLayoutEffect } from 'react';
import * as NoticiaAction from '../../store/actionsgazeta/noticia.actions'
import * as qs from 'query-string';
import { useHistory } from 'react-router-dom'

const NoticiaOldPage = (props) => {
    let history = useHistory();

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
        if (props) {
            const parsed = qs.parse(props.location.search);
            if (parsed.id)
                NoticiaAction.getOld(parsed.id)
                    .then((data) => {
                        let dataCompleta = data.data.split("T")[0]
                        let dataSeparada = dataCompleta.split("-")
                        history.push(`/noticia/${dataSeparada[0]}/${dataSeparada[1]}/${dataSeparada[2]}/${data.chave}`);
                    })
                    .catch(() => history.push(`/`))
        }

    }, [props, history]);
    return (
        <Fragment>

        </Fragment >
    )
};
export default NoticiaOldPage;