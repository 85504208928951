import { api } from '../settings'
import axios from 'axios';
export const SET_COLUNAS_CAPA_DATA = 'SET_COLUNAS_CAPA_DATA';
export const CLEAR_COLUNAS_CAPA_DATA = 'CLEAR_COLUNAS_CAPA_DATA';

export const SET_COLUNAS_CAPA_MIDIAS = 'SET_COLUNAS_CAPA_MIDIAS';
export const CLEAR_COLUNAS_CAPA_MIDIAS = 'CLEAR_COLUNAS_CAPA_MIDIAS';


export const get = (limit = 10, tipo = "Normal") => async (dispatch, getState) => {
    let apiService = `/open/ultimascolunas`
    apiService += `?limit=${limit}`
    //if (tipo === "MaisLidas") apiService += `&maisLidasSemana=${true}`

    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type:
                tipo === "Normal" ? SET_COLUNAS_CAPA_DATA : null
            ,
            payload: response.data
        })

    } catch (error) {
        console.log(error)
    }
}
