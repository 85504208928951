import { api } from '../settings'
import axios from 'axios';
export const SET_PROGRAMAS_DATA = 'SET_PROGRAMAS_DATA';
export const CLEAR_PROGRAMAS_DATA = 'CLEAR_PROGRAMAS_DATA';

export const get = (limit = 20) => async (dispatch, getState) => {
    let apiService = `/open/programas`
    apiService += `?limit=${limit}`

    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type: SET_PROGRAMAS_DATA,
            payload: response.data
        })


    } catch (error) {
        console.log(error)
    }
}
