import React, { useLayoutEffect } from 'react'
import * as PrevisaoAction from '../store/actionsgazeta/previsao.actions'
import * as SecoesAction from '../store/actionsgazeta/secoes.actions'
import * as CategoriasAction from '../store/actionsgazeta/categorias.actions'
import * as NoticiasAction from '../store/actionsgazeta/noticias.actions'
import * as ColunistasAction from '../store/actionsgazeta/colunistas.actions'
import * as PropagandasAction from '../store/actionsgazeta/propagandas.actions'
import { useDispatch } from 'react-redux'

export default function Launcher(props) {
    const dispatch = useDispatch()

    useLayoutEffect(() => {
        //TODO lendos dados iniciais para montar a tela
        console.log("launcher")
        dispatch(PrevisaoAction.get())
        dispatch(SecoesAction.get())
        dispatch(CategoriasAction.get())
        dispatch(ColunistasAction.get())
        dispatch(NoticiasAction.get(10, "MaisLidas"))
        dispatch(PropagandasAction.get())

    }, [dispatch])

    return <>{props.children}</>
}
