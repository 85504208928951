import { api } from '../settings'
import axios from 'axios';
export const SET_SECAO_DATA = 'SET_SECAO_DATA';
export const CLEAR_SECAO_DATA = 'CLEAR_SECAO_DATA';


export const get = (chave) => async (dispatch, getState) => {
    const apiService = `/open/secao/${chave}`
    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type: SET_SECAO_DATA,
            payload: response.data
        })


    } catch (error) {
        console.log(error)
    }
}
