import { api } from '../settings'
import axios from 'axios';

export const SET_PROGRAMA_ITENS_DATA = 'SET_PROGRAMA_ITENS_DATA';
export const CLEAR_PROGRAMAM_ITENS_DATA = 'CLEAR_PROGRAMAM_ITENS_DATA';


export const get = (chavePrograma, limit = 10, offSet = 0) => async (dispatch, getState) => {
    let apiService = `/open/programas/${chavePrograma}`
    apiService += `?limitItens=${limit}`
    apiService += `&offSetItens=${offSet}`

    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        dispatch({
            type: SET_PROGRAMA_ITENS_DATA,
            payload: response.data
        })

    } catch (error) {
        console.log(error)
    }
}
