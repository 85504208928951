import * as Actions from '../actionsgazeta';

const initialState = {};

const secao = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SECAO_DATA:
            {
                return action.payload

            }
        case Actions.CLEAR_SECAO_DATA:
            {
                return state;
            }
        default:
            {
                return state
            }
    }
};

export default secao;
