import React, { Fragment, useState, useLayoutEffect } from 'react';
import FontAwesome from "../uiStyle/FontAwesome";
import { Link, NavLink } from "react-router-dom";
import SearchModal from "../SearchModal";
import SidebarMenu from "../SidebarMenu";
import { useSelector, useDispatch } from "react-redux";
import ico670logo from '../../doc/img/logo/ico670logo.png';
import ico1003logo from '../../doc/img/logo/ico1003logo.png';
import * as RadioAction from '../../store/actionsgazeta/radio.actions'
import useGaTracker from '../../launcher/useGaTracker'

/*
const menus = [
    {
        id: 1,
        linkText: 'Home',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 11,
                link: '/',
                linkText: 'Home 1'
            },
            {
                id: 12,
                link: '/dark',
                linkText: 'Home Dark'
            },
            {
                id: 13,
                new: true,
                link: '/home-two',
                linkText: 'Home 2'
            },
            {
                id: 14,
                link: '/home-three',
                linkText: 'Home 3'
            },
        ]
    },
    {
        id: 2,
        linkText: 'Pages',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 21,
                link: '/about',
                linkText: 'About'
            },
            {
                id: 22,
                link: '/archive',
                linkText: 'Archive'
            },
            {
                id: 23,
                link: '/contact',
                linkText: 'Contact Us'
            },
            {
                id: 24,
                link: '/404',
                linkText: '404'
            },
        ]
    },
    {
        id: 3,
        linkText: 'Posts',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 31,
                child: true,
                linkText: 'General Posts',
                third_menu: [
                    {
                        id: 311,
                        link: '/post1',
                        linkText: 'Post 1',
                    },
                    {
                        id: 312,
                        link: '/post2',
                        linkText: 'Post 2',
                    },
                    {
                        id: 313,
                        link: '/post3',
                        linkText: 'Post 3',
                    },
                ],
            },
            {
                id: 32,
                child: true,
                linkText: 'Video Posts',
                third_menu: [
                    {
                        id: 321,
                        link: '/video_post1',
                        linkText: 'Video Style 1',
                    },
                    {
                        id: 322,
                        link: '/video_post2',
                        linkText: 'Video Style 2',
                    },
                    {
                        id: 323,
                        link: '/video_post3',
                        linkText: 'Video Style 3',
                    },
                ],
            },
            {
                id: 33,
                child: true,
                linkText: 'Audio Posts',
                third_menu: [
                    {
                        id: 331,
                        link: '/audio_post1',
                        linkText: 'Audio Style 1',
                    },
                    {
                        id: 332,
                        link: '/audio_post2',
                        linkText: 'Audio Style 2',
                    },
                    {
                        id: 333,
                        link: '/audio_post3',
                        linkText: 'Audio Style 3',
                    },
                ],
            },
            {
                id: 34,
                child: true,
                linkText: 'Sidebars',
                third_menu: [
                    {
                        id: 341,
                        link: '/post1',
                        linkText: 'Right Sidebar',
                    },
                    {
                        id: 342,
                        link: '/left_post2',
                        linkText: 'Left Sidebar',
                    },
                    {
                        id: 343,
                        link: '/post2',
                        linkText: 'No Sidebar',
                    },
                ],
            },
        ]
    },
    {
        id: 4,
        linkText: 'Categories',
        child: true,
        icon: 'angle-down',
        submenu: [
            {
                id: 41,
                link: '/business',
                linkText: 'Business'
            },
            {
                id: 42,
                link: '/entertainment',
                linkText: 'Entertainment'
            },
            {
                id: 43,
                link: '/features',
                linkText: 'Features'
            },
            {
                id: 44,
                link: '/sports',
                linkText: 'Sports'
            },
            {
                id: 45,
                link: '/trending',
                linkText: 'Trending'
            },
        ]
    },
    {
        id: 5,
        linkText: 'World',
        link: '/world'
    },
    {
        id: 6,
        linkText: 'Sports',
        link: '/sports'
    },
    {
        id: 7,
        linkText: 'Contact',
        link: '/contact'
    },
];

*/
const MainMenu = ({ className, dark }) => {
    const dispatch = useDispatch();
    
    const previsao = useSelector(state => state.gazeta.previsao)
    const secoes = useSelector(state => state.gazeta.secoes)
    const categorias = useSelector(state => state.gazeta.categorias)
    const colunistas = useSelector(state => state.gazeta.colunistas)

    const [searchShow, setSearchShow] = useState(false);
    const [sideShow, setSideShow] = useState(false);
    const [opcoes, setOpcoes] = useState([]);
    useGaTracker();
    
    useLayoutEffect(() => {
        
        // Menus Iniciais
        let opcoesIni = [{
            id: -1,
            linkText: 'Capa',
            link: '/'
        }]

        let opcoesFim = [
            {
                id: 1000,
                linkText: 'Nossas Rádios',
                link: '/radios',
                child: true,
                icon: 'angle-down',
                tamanho: "400px",
                submenu: [
                    {
                        id: 70,
                        link: `/`,
                        linkText: "Gazeta AM 670",
                        radio: 'AM',
                        tamanho: "50%"
                    }, {
                        id: 70,
                        link: `/`,
                        linkText: "Gazeta FM 100.3",
                        radio: 'FM',
                        tamanho: "50%"
                    }
                ]
            },
            {
                id: 1002,
                linkText: 'Negócios',
                link: '/negocios'
            },
            {
                id: 1001,
                linkText: 'Fale Conosco',
                link: '/contato'
            }]

        let dadosSecoes = secoes.filter(item => item.tipo === 0)
            .map((item, key) => {
                return Object.assign({}, {
                    id: key,
                    linkText: item.menu,
                    link: `/secao/${item.chave}`,
                    tipo: item.tipo === 0 ?
                        'Cabeçalho' :
                        item.tipo === 1 ?
                            'Rodapé' :
                            item.tipo === 2 ?
                                'Capa' : "Faq"
                })
            })


        let dadosCategorias = categorias.map((item, key) => {
            return Object.assign({}, {
                id: 21,
                link: `/categoria/${item.chave}`,
                linkText: item.nome,
                tamanho: "25%"
            })
        })


        let dadosColunistas = colunistas.map((item, key) => {
            return Object.assign({}, {
                id: 21,
                link: `/colunista/${item.chave}`,
                linkText: item.nome,
            })
        })


        const allPromise = Promise.all([dadosSecoes, dadosCategorias, dadosColunistas]);

        allPromise.then(values => {

            let opcoesCategoria = {
                id: 2,
                linkText: 'Notícias',
                child: true,
                icon: 'angle-down',
                tamanho: "600px",
                submenu: values[1]
            }

            let opcoesColunistas = {
                id: 200,
                linkText: 'Colunistas',
                child: true,
                icon: 'angle-down',
                tamanho: "600px",
                submenu: values[2]
            }


            setOpcoes(
                opcoesIni
                    .concat(opcoesCategoria)
                    .concat(values[0])
                    .concat(opcoesColunistas)
                    .concat(opcoesFim)
                //.concat(menus)
                //TODO Adicionar Menus do Template
            )




        }).catch(error => {

        });



    }, [secoes, categorias, colunistas]);


    //const arr = dark ? menusDark : menus;
    return (
        <Fragment>
            <div className={`main-menu ${className ? className : ''}`} id="header">
                <Link to="#top" className="up_btn up_btn1">
                    <FontAwesome name="chevron-double-up" />
                </Link>
                <div className="main-nav clearfix is-ts-sticky">
                    <div className="container">
                        <div className="row justify-content-between">
                            <nav className="navbar navbar-expand-lg col-lg-8 align-self-center">
                                <div className="site-nav-inner">
                                    <button className="navbar-toggler" onClick={() => setSideShow(true)}>
                                        <FontAwesome name="bars" />
                                    </button>
                                    <div id="navbarSupportedContent"
                                        className="collapse navbar-collapse navbar-responsive-collapse">
                                        <ul className="nav navbar-nav" id="scroll">
                                            {opcoes.length > 0 ? opcoes.map((item, i) => (
                                                <li key={i}
                                                    className={`
                                                ${item.child ? 'dropdown' : ''}
                                                nav-item`}>
                                                    {item.child ? <NavLink onClick={e => e.preventDefault()} to="/"
                                                        className="menu-dropdown"
                                                        data-toggle="dropdown">{item.linkText}
                                                        <FontAwesome
                                                            name={item.icon} /></NavLink>
                                                        : <NavLink to={item.link} className="menu-dropdown"
                                                            data-toggle="dropdown">{item.linkText} <FontAwesome
                                                                name={item.icon} /></NavLink>}

                                                    {item.child ?
                                                        <ul className="dropdown-menu" role="menu" style={{ width: item.tamanho }}>
                                                            {item.submenu.map((sub_item, i) => (
                                                                <li key={i}
                                                                    className={`${sub_item.child ? 'dropdown-submenu' : null}
                                                        `}
                                                                    style={{ width: sub_item.tamanho ? sub_item.tamanho : "33%", float: "left" }}
                                                                >
                                                                    {sub_item.child ?
                                                                        <NavLink onClick={e => e.preventDefault()}
                                                                            to="/">{sub_item.linkText}
                                                                            <FontAwesome
                                                                                name={item.icon} />
                                                                        </NavLink>
                                                                        : <NavLink
                                                                            to={sub_item.link}
                                                                            onClick={async () => {
                                                                                if (sub_item.radio) {
                                                                                    await dispatch(RadioAction.close())
                                                                                    dispatch(RadioAction.set(sub_item.radio))
                                                                                }
                                                                            }}
                                                                        >
                                                                            {sub_item.radio ?
                                                                                <img src={sub_item.radio === "AM" ? ico670logo : ico1003logo} alt="logo" style={{ height: 25 }} />
                                                                                : sub_item.linkText}
                                                                        </NavLink>}
                                                                    {sub_item.third_menu ?
                                                                        <ul className="dropdown-menu">
                                                                            {sub_item.third_menu.map((third_item, i) => (
                                                                                <li key={i}><NavLink
                                                                                    to={third_item.link}>{third_item.linkText} aaa
                                                                                    <FontAwesome
                                                                                        name={item.icon} />
                                                                                </NavLink>
                                                                                </li>
                                                                            ))}
                                                                        </ul> : null}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        : null
                                                    }
                                                </li>
                                            )) : null}
                                        </ul>
                                    </div>
                                    <SidebarMenu sideShow={sideShow} setSideShow={setSideShow} menus={opcoes} />
                                </div>
                            </nav>
                            <div className="col-lg-4 align-self-center">
                                <div className="menu_right">
                                    <div className="users_area">
                                        <ul className="inline">
                                            <li className="search_btn">
                                                <NavLink
                                                    to={"/pesquisar"}>
                                                    <FontAwesome name="search" />
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                    {
                                        /*
                                    <div className="lang d-none d-xl-block">
                                        <ul>
                                            <li><Link to="/">English <FontAwesome name="angle-down"/></Link>
                                                <ul>
                                                    <li><Link to="/">Spanish</Link>
                                                    </li>
                                                    <li><Link to="/">China</Link>
                                                    </li>
                                                    <li><Link to="/">Hindi</Link>
                                                    </li>
                                                    <li><Link to="/">Corian</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                        */
                                    }
                                    {previsao.temp_min &&
                                        <div className="temp d-none d-lg-block">
                                            <div className="temp_wap">
                                                <div className="temp_icon">
                                                    <img src={previsao.icone} alt="temp icon" title={previsao.resumo} />
                                                </div>
                                                <h3
                                                    style={{ marginRight: 20 }}
                                                    className="temp_count">
                                                    {previsao.temp_min}º
                                                    <p>min</p>
                                                </h3>
                                                <h3 className="temp_count">
                                                    {previsao.temp_max}º
                                                    <p>max</p>
                                                </h3>
                                                <p>Carazinho - RS</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            {
                searchShow ?
                    <SearchModal setSearchShow={setSearchShow} searchShow={searchShow} />
                    : null
            }
        </Fragment >
    );
};

export default MainMenu;