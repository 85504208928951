import * as Actions from '../actionsgazeta';

const initialState = [];

const propagandas = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_PROPAGANDAS_CAPA_DATA:
            {
                return action.payload

            }
        case Actions.CLEAR_PROPAGANDAS_CAPA_DATA:
            {
                return state;
            }
        default:
            {
                return state
            }
    }
};

export default propagandas;
