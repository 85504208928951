import React from 'react'
import FontAwesome from "../../components/uiStyle/FontAwesome";

export default function index() {
    return (
        <>
            <li title='SoundCloud'><a href='https://soundcloud.com/grupogazeta' rel="noopener noreferrer" target='_blank'><FontAwesome name="soundcloud" /></a></li>
            <li title='Insta Gazeta AM'><a href='https://www.instagram.com/grupogazeta/' rel="noopener noreferrer" target='_blank'><FontAwesome name="instagram" /></a></li>
            <li title='Insta Gazeta FM'><a href='https://www.instagram.com/gazetafmoficial/' rel="noopener noreferrer" target='_blank'><FontAwesome name="instagram" /></a></li>
            <li title='Facebook'><a target='_blank' href='https://www.facebook.com/portalgazetacarazinho' rel="noopener noreferrer"><FontAwesome name="facebook-f" /></a></li>
            <li title='Youtube'><a href='https://www.youtube.com/channel/UCA9EDjKp3QIFtQO3OOHadSw' target='_blank' rel="noopener noreferrer"><FontAwesome name="youtube-play" /></a></li>
        </>
    )
}
