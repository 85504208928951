import { api } from '../settings'
import axios from 'axios';

export const SET_SECOES_DATA = 'SET_SECOES_DATA';
export const CLEAR_SECOES_DATA = 'CLEAR_SECOES_DATA';

export const get = () => async (dispatch, getState) => {
    const apiService = `/open/secao`
    const request = axios.get(`${api}${apiService}`);
    try {
        const response = await request
        return dispatch({
            type: SET_SECOES_DATA,
            payload: response.data
        })

    } catch (error) {
        console.log(error)
    }
}
